<igx-dialog #confirmationDialog [closeOnOutsideSelect]="false">
  <igx-dialog-title>
    <div class="dialog-container">
      <span class="dialog-title">{{title}}</span>
      <igx-icon class="icon-btn-close" (click)="cancel()" family="material">close</igx-icon>
    </div>
  </igx-dialog-title>
  <div class="main-content">
    <div class="msg-area">
      <ng-content></ng-content>
    </div>
  </div>


  <div igxDialogActions class="dialog-action-bar">
    <button *ngIf="clearBtn" class="cancel-btn" igxButton (click)="cancel()">{{clearBtnTitle}}</button>
    <button *ngIf="okBtn" class="confirm-btn" igxButton (click)="confirm()">{{okBtnTitle}}</button>
  </div>
</igx-dialog>

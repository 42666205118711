<div igxOverlayOutlet>
  <igx-dialog #chooseDisplayCategoryDialog [closeOnOutsideSelect]="true"
    (closing)="closingDialog($event)"
    (opening)="openingDialog()"
  >
  <igx-dialog-title>
    <div class="dialog-container">
      <span class="dialog-title">表示カテゴリ選択</span>
      <!-- <span class="help-btn">?</span> -->
    </div>
  </igx-dialog-title>
  <div class="main-content">
    <div class="explorer-wrapper">
      <div class="explorer">
        <div class="address-bar">
          {{currentSelected}}
        </div>
        <div class="main-area">
          <div class="nav-window">
            <nav>
              <ng-container *ngFor="let app of apps">
                <span class="nav-item" (click)="chooseNode(app)" [class.current-selected]="selectedClass(app)">
                  <ng-container *ngIf="app.children && app.children.length">
                    <igx-icon (click)="expandTreeNode(app, $event)" class="expand-btn" family="material">{{app.expand ? 'expand_more' : 'chevron_right'}}</igx-icon>
                  </ng-container>
                  <span class="item-title">{{ app.name }}</span>
                </span>

                <ng-container *ngIf="app.children && app.children.length && app.expand">
                  <ng-container *ngFor="let subApp of app.children">
                    <span class="nav-item level-1" (click)="chooseNode(subApp, app)" [class.current-selected]="subApp.selected">
                      <ng-container *ngIf="subApp.children && subApp.children.length">
                        <igx-icon (click)="expandTreeNode(subApp, $event)" class="expand-btn" family="material">{{subApp.expand ? 'expand_more' : 'chevron_right'}}</igx-icon>
                      </ng-container>
                      <span class="item-title">{{ subApp.name }}</span>
                    </span>
                  </ng-container>
                </ng-container>


              </ng-container>
            </nav>
          </div>
          <!-- <div class="detail-window">
            <nav>
              <ng-container *ngFor="let app of selectedNode">
                <span class="nav-item detail-item" (click)="chooseChildrenNode(app, selectedNode)" [class.current-selected]="app.selected">
                  <ng-container *ngIf="app.children && app.children.length">
                    <igx-icon (click)="expandTreeNode(app, $event)" class="expand-btn" family="material">{{app.expand ? 'expand_more' : 'chevron_right'}}</igx-icon>
                  </ng-container>
                  <span class="item-title">{{ app.name }}</span>
                </span>
              </ng-container>
            </nav>
          </div> -->
        </div>
      </div>
    </div>
  </div>


  <div igxDialogActions class="dialog-action-bar">
    <button igxButton (click)="confirm()">決定</button>
  </div>
</igx-dialog>

</div>

import { routes } from '@app/app.routes'
import { FormsModule } from '@angular/forms'
import { provideRouter } from '@angular/router'
import { registerLocaleData } from '@angular/common'
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http'
import { NumberInputPipe } from '@app/helpers/pipes/number-input.pipe'
import { provideAnimations } from '@angular/platform-browser/animations'
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core'

import localeFr from '@angular/common/locales/ja'
// import { fakeBackendInterceptor } from './helpers/fake-backend'
registerLocaleData(localeFr)

export const appConfig: ApplicationConfig = {
  providers: [
    NumberInputPipe,
    provideAnimations(),
    provideRouter(routes),
    importProvidersFrom(FormsModule),
    importProvidersFrom(HttpClientModule),
    provideHttpClient(
      withInterceptors([
        // fakeBackendInterceptor  // TODO: 🚨 テスト用、リリース前除外 🚨
      ])
    )
    // { provide: LOCALE_ID, useValue: 'ja' }
  ]
}

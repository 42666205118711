import { CommonModule } from '@angular/common'
import { Component, ViewChild, OnInit, Input } from '@angular/core'
import { cloneObj, isNullOrEmpty } from '@app/helpers/utils.toolkit'
import { AccountService } from '@app/services/account.service'
import { IgxButtonModule, IgxDialogComponent, IgxDialogModule, IgxIconModule } from '@infragistics/igniteui-angular'

@Component({
  selector: 'app-choose-display-category',
  standalone: true,
  imports: [CommonModule, IgxDialogModule, IgxButtonModule, IgxIconModule],
  templateUrl: './choose-display-category.component.html',
  styleUrl: './choose-display-category.component.scss'
})
export class ChooseDisplayCategoryComponent implements OnInit {
  @ViewChild('chooseDisplayCategoryDialog', { static: true })
  public chooseDisplayCategoryDialog!: IgxDialogComponent

  selectedNode: any = null
  apps: any[] = []
  constructor(
    private accountService: AccountService,
  ) {
    this.apps = cloneObj(this.accountService.applications)
  }

  get currentSelected(): string {
    let ret = '未選択'
    const app = this.apps.find((x: any) => x.selected)
    if (app) {
      const subApp = app.children.find((x: any) => x.selected)
      ret = app.name
      if (subApp) {
        ret = `${app.name} ＞ ${subApp.name}`
      }
    }
    return ret
  }

  selectedClass(obj: any): boolean {
    const app = this.apps.find((x: any) => x.selected && x.applicationId === obj.applicationId && x.code === obj.code)
    let ret = false
    if (app) {
      const subApp = app.children.find((x: any) => x.selected)
      ret = !subApp
    }
    return ret
  }

  closingDialog(event: any): void {
    if (!this.accountService.currentAppId) {
      event.cancel = true
    }
  }

  chooseNode(obj: any, parentObj: any = null): void {
    this.clearSelected(this.apps)
    if (parentObj) {
      this.selectedNode = parentObj.children
      parentObj.selected = true
      obj.selected = true
    } else {
      this.selectedNode = obj.children
      obj.selected = true
    }
  }

  chooseChildrenNode(subApp: any, apps: any | null = null): void {
    this.clearSelected(apps)
    subApp.selected = true
  }

  expandTreeNode(obj: any, event: any): void {
    obj.expand = !obj.expand
    event.stopPropagation()
  }

  openingDialog(): void {
    const currentAppId = this.accountService.currentAppId
    this.apps = cloneObj(this.accountService.applications)
    if (this.accountService.applicationId && currentAppId) {
      this.apps.forEach(app => {
        if (app && app.children && app.children.length) {
          app.children.forEach((subApp: any) => {
            if (subApp.applicationId === currentAppId) {
              app.expand = true
              this.selectedNode = app.children
              subApp.selected = true
            }
          })
        }
      })
    }
  }

  ngOnInit(): void {
    if (!this.accountService.currentAppId) {
      this.chooseDisplayCategoryDialog.open()
    }
  }

  openDialog(): void {
    this.chooseDisplayCategoryDialog.open()
  }

  confirm(): void {
    const selectedApp = this.apps.find(x => x.selected)
    const selectedSubApp = selectedApp?.children.find((x: any) => x.selected)
    if (selectedApp) {
      if (selectedSubApp) {
        // 事業選択
        if (selectedSubApp.applicationId !== this.accountService.currentAppId) {
          this.accountService.currentAppId = selectedSubApp.applicationId
        }
      } else {
        // サプライヤー単位選択
        this.accountService.supplierCode = selectedApp.code
        this.accountService.currentAppId = selectedApp.applicationId
      }
      this.chooseDisplayCategoryDialog.close()
    }
  }

  private clearSelected(apps: any): void {
    if (!isNullOrEmpty(apps) && Array.isArray(apps)) {
      apps.forEach(x => {
        x.selected = false
        if (x.children) {
          this.clearSelected(x.children)
        }
      })
    }
  }
}

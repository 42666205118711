<div class="content-container">
  <h6 class="view-title">売上計画登録 (事業別)</h6>
  <div class="header-action-bar-area">
    <div class="action-frame"><label class="fiscal-year-title">年度</label>
      <select [(ngModel)]="targetYear" class="fiscal-year-select normal-select" name="selectedYear" (ngModelChange)="changeSelectedYear($event)">
        <option *ngFor="let item of yearList" [selected]="targetYear === item.value" [ngValue]="item.value">{{item.name}}
        </option>
      </select>
      <button class="action-btn get-data-btn" (click)="showSalesPlan()">表示</button>
    </div>

    <div class="amount-unit">
      <select [ngModel]="currentUnit" name="selectedUnit" class="normal-select" (ngModelChange)="changeAmountUnit($event)">
        <option *ngFor="let item of amountUnitItems" [selected]="item.value === currentUnit" [ngValue]="item.value">{{item.name}}</option>
      </select></div>
  </div>
  <div style="width: 100%;margin: 16px auto;">
    <div class="grid-area" *ngIf="gridData && gridData.length && !loading; else elseBlock">
      <table>
        <thead>
          <th style="width: 85px; min-width: 85px;"></th>
          <ng-container *ngFor="let item of gridData; let i = index">
            <th [class]="widthVal()" *ngIf="i <= 11">{{item.month}}月</th>
          </ng-container>
          <th [class]="widthVal()">合計</th>
        </thead>
        <tbody>
          <tr>
            <td style="background-color: #f0f0f0;text-align: center;">売上高</td>
            <ng-container *ngFor="let item of gridData; let i = index">
              <td *ngIf="i <= 11" class="input-block data-tpye-number">
                <input id="{{item.yearMonth}}" (keyup)="onEnter($event, i)"
                  (focusout)="onFocusOut($event, item)"
                  [class.validate-failed]="!isValidValue(item.value, true)"
                  class="input-item"
                  type="text"
                  value=""
                  [disabled]="currentSelectedIsSupplier"
                  numberInput
                  IsNumberInput
                  [ngModel]="item.value | numberInput: '1.0-0'" />
              </td>
            </ng-container>
            <td style="background-color: #f0f0f0; text-align: right;">{{planTotal | number : '1.0-0'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #elseBlock>
      <div class="skeleton-block">
        <div class="skeleton-msg" *ngIf="!loading; else elseBlock">「表示」ボタンをクリックしてください</div>
        <ng-template #elseBlock>
          <div class="skeleton-msg">Loading...</div>
        </ng-template>
      </div>
    </ng-template>
  </div>
  <div class="footer-action-area">
    <ng-container *ngIf="!currentSelectedIsSupplier">
      <button class="action-btn action-btn-base" [disabled]="submitting" (click)="registerSalesPlan()">
        <div *ngIf="submitting" class="loading"></div>登録</button>
    </ng-container>
  </div>
</div>

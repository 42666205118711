import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { excludeCommas, isNullOrEmpty } from '@app/helpers/utils.toolkit'
import { NumberInputPipe } from '@app/helpers/pipes/number-input.pipe'
import { FormsModule } from '@angular/forms'
import { NumberInputDirective } from '@app/helpers/directives/number-input.directive'

@Component({
  selector: 'app-editable-grid',
  standalone: true,
  imports: [CommonModule, NumberInputPipe, FormsModule, NumberInputDirective, DecimalPipe],
  templateUrl: './editable-grid.component.html',
  styleUrl: './editable-grid.component.scss'
})
export class EditableGridComponent implements OnInit, OnDestroy {
  @Input() gridData: any[] = []
  @Input() headerData: any[] = []
  @Input() rowInfo: any[] = []
  @Input() currentUnit: number = 1000

  planTotal: number | null = null

  constructor(){}

  ngOnInit(): void {

  }

  get data(): any[] {
    return this.gridData
  }


  onEnter($event: any, item: any, rowIndex: number, colIndex: number): void {
    if ($event.key !== 'Enter') return

    if ($event.shiftKey) {
      // 上へ
      console.log(`Shift + Enter 上へ rowIndex: ${rowIndex}`)
      if (rowIndex === this.rowInfo.length - 1) {
        const target = this.gridData.filter(x => x.dataType !== item.dataType)[colIndex]
        console.log(`Enter 上へ target:`, target)
        if (target) {
          const id = target.dataType + target.yearMonth
          document.getElementById(id)?.focus()
        }
      }
    } else {
      // 下へ
      console.log(`Enter 下へ rowIndex: ${rowIndex}`)
      if (rowIndex === 0) {
        const target = this.gridData.filter(x => x.dataType !== item.dataType)[colIndex]
        console.log(`Enter 下へ target:`, target)
        if (target) {
          const id = target.dataType + target.yearMonth
          document.getElementById(id)?.focus()
        }
      }
    }
  }

  /**
   * Inputのフォーカスを失う際　有効性チェック、計算
   * @param event
   * @param item
   */
  onFocusOut(event: any, item: any, rowInfo: any) {
    const target = event.target
    const targetVal = excludeCommas(target.value)
    if (!this.isValidValue(targetVal, true)) {
      console.log(`入力した値が無効：${item.value}`)
      return
    }
    item.value = targetVal
    const gridData = this.gridData
    let totalVal: number = 0
    gridData.forEach((obj: any, index: number) => {
      if (rowInfo.value === obj.dataType) {
        const salesPlanObj = gridData[index]
        const salesPlanVal = this.isValidValue(salesPlanObj.value) ? Number(salesPlanObj.value) : 0
        totalVal += salesPlanVal
      }
    })

    rowInfo.total = totalVal > 0 ? totalVal : null
  }

  // // 単位変換
  // private convertUnit(val: number, currentUnit: number, fullAmount: boolean): number | null {
  //   if (isNullOrEmpty(val)) return null
  //   let ret = 0
  //   // 変換不要やできないものは除外
  //   if (!isNullOrEmpty(val) && val !== 0 && !isNaN(Number(val))) {
  //     if (fullAmount) {
  //       // 保存など、元の数字(通常単位：1円)に戻す　小数点以下切り捨て
  //       ret = Math.trunc(Number(val) * currentUnit)
  //     } else {
  //       // 表示/編集など、指定単位に変換  小数点以下切り捨て
  //       ret = Math.trunc(Number(val) / currentUnit)
  //     }
  //   }
  //   return ret > 0 ? ret : null
  // }

  /**
   * 入力有効性検証
   * 数字であるかをチェック
   * @param val
   */
  isValidValue(val: any, allowEmpty: boolean = false): boolean {
    if (allowEmpty && isNullOrEmpty(val)) {
      return true
    }
    return isNullOrEmpty(val) || isNaN(Number(val)) ? false : true
  }

  getRowData(rowInfo: any): any[] {
    return this.gridData.filter(x => x.dataType === rowInfo.value)
  }

  widthVal(): string {
    if (this.currentUnit === 1) {
      return 'col-3'
    } else if (this.currentUnit === 1000) {
      return 'col-6'
    } else if (this.currentUnit === 1000000) {
      return 'col-12'
    }
    return 'col-6'
  }

  ngOnDestroy() {
    console.log(`${this.constructor.name} onDestroy`)
  }
}

import { NumberInputPipe } from '../pipes/number-input.pipe'
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core'
/**
 * 自前構造ディレクティブ Input要素の桁区切り
 */
@Directive({ selector: '[IsNumberInput]', standalone: true })
export class NumberInputDirective implements OnInit{

  private elem: HTMLInputElement
  private digits: string = '1.0-0'

  constructor(
    private elemRef: ElementRef,
    private numberInputPipe: NumberInputPipe
  ) {
    this.elem = this.elemRef.nativeElement
  }

  ngOnInit(): void {
    this.elem.value = this.numberInputPipe.transform(this.elem.value, this.digits)
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(val: string): void {
    this.elem.value = this.numberInputPipe.parse(val)
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(val: string): void {
    this.elem.value = this.numberInputPipe.transform(val, this.digits)
  }
}

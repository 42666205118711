<table>
  <thead>
    <th style="min-width: 110px;width: 110px"></th>
    <ng-container *ngFor="let item of headerData; let i = index">
      <th [class]="widthVal()">{{item.month}}月</th>
    </ng-container>
    <th [class]="widthVal()">合計</th>
  </thead>
  <tbody>
    <tr *ngFor="let row of rowInfo; let r = index">
      <td style="background-color: #f0f0f0;text-align: center; padding: 0;">{{row.name}}</td>
      <ng-container *ngFor="let item of getRowData(row); let c = index">
        <td class="input-block data-tpye-number">
          <input id="{{item.dataType + item.yearMonth}}" (keyup)="onEnter($event, item, r, c)"
            (focusout)="onFocusOut($event, item, row)"
            [class.validate-failed]="!isValidValue(item.value, true)"
            class="input-item"
            type="text"
            [disabled]="row.disabled"
            value=""
            numberInput
            IsNumberInput
            [ngModel]="item.value | numberInput: '1.0-0'" />
        </td>
      </ng-container>
      <td style="background-color: #f0f0f0; text-align: right;">{{row.total | number : '1.0-0'}}</td>
    </tr>
  </tbody>
</table>

import { RouterLink } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { IgxButtonModule, IgxTooltipModule, IgxIconModule } from '@infragistics/igniteui-angular'

import { SideMenuService } from '@app/services/side-menu.service'
import { FiscalYearCalendarService } from '@app/services/fiscal-year-calendar.service'
import { AppConsts } from '@app/configs/app.configs'

@Component({
  selector: 'app-common-action-area',
  standalone: true,
  imports: [CommonModule, FormsModule, IgxTooltipModule, RouterLink, IgxButtonModule, IgxIconModule],
  templateUrl: './common-action-area.component.html',
  styleUrl: './common-action-area.component.scss'
})
export class CommonActionAreaComponent {
  @Output() submit = new EventEmitter<any>()
  @Output() changeUnit = new EventEmitter<any>()
  /** 年度指定 */
  @Input() targetYear: number = new Date().getFullYear()
  @Input() targetMonth: number = new Date().getMonth() + 1
  @Input() currentView: string = '/expected/sales'
  @Input() currentViewTitle: string = '売上見込み'
  @Input() updatedDate: Date | null = null
  @Input() amountUnit = 1000
  @Input() hintUrl = ''

  selectedYear = new Date().getFullYear()
  selectedMonth = new Date().getMonth() + 1

  salesForecast = '/expected/sales'
  salesCollectionProspects = '/expected/collection'
  /** 年度リスト */
  yearList: any[] = []
  /** 月リスト */
  monthList: readonly any[] = []

  /** 金額の単位指定 */
  amountUnitItems: readonly any[]

  constructor(
    private sideMenuService: SideMenuService,
    private fiscalYearCalendar: FiscalYearCalendarService,
  ) {
    this.amountUnitItems = AppConsts.amountUnitItems
  }

  ngOnInit(): void {
    this.yearList = this.fiscalYearCalendar.getYearList()
    this.monthList = this.fiscalYearCalendar.getMonths()
    this.checkInputYearMonthVal()
  }

  private checkInputYearMonthVal(): void {
    if (this.yearList.find((x: any) => x.value === this.targetYear)) {
      this.selectedYear = this.targetYear
    }
    if (this.monthList.find((x: any) => x.value === this.targetMonth)) {
      this.selectedMonth = this.targetMonth
    }
  }

  changeView(menu: string): void {
    this.sideMenuService.changedMenu(menu)
    this.yearList = this.fiscalYearCalendar.getYearList()

  }

  changeSelectedYear(year: any): void {
    this.selectedYear = year
  }

  changeSelectedMonth(month: any): void {
    this.selectedMonth = month
  }

  clickShowBtn(): void {
    this.submit.emit({ year: this.selectedYear, month: this.selectedMonth, unit: this.amountUnit })
  }

  changeAmountUnit(val: any): void {
    this.amountUnit = val
    this.changeUnit.emit({ unit: this.amountUnit })
  }
}

<div class="action-btn-wrapper">
  <button class="action-btn action-btn-base" (click)="downloadCsv()">
    <span class="btn-icon"><img class="menu-icon" src="./assets/images/download.svg" width="16" height="16" alt="" /></span>

    <span class="btn-title">表示中の内容をCSV形式ダウンロード</span>
  </button>

  <button *ngIf="showBackButton" class="action-btn action-btn-base back-btn" (click)="backBack()">
    <span class="btn-title">戻る</span>
  </button>
</div>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IgxDialogComponent, IgxDialogModule, IgxIconModule } from '@infragistics/igniteui-angular'

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, IgxDialogModule, IgxIconModule],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
  @ViewChild('confirmationDialog', { static: true })
  public confirmationDialog!: IgxDialogComponent

  @Input() clearBtn = false
  @Input() okBtn = true
  @Input() okBtnTitle = 'はい'
  @Input() clearBtnTitle = 'キャンセル'
  @Input() title = '変更内容ご確認ください'
  @Output() submit = new EventEmitter<any>()

  openDialog(): void {
    this.confirmationDialog.open()
  }

  cancel(): void {
    this.confirmationDialog.close()
  }

  confirm(): void {
    this.submit.emit()
    this.confirmationDialog.close()
  }
}

<div class="content-container">
  <h6 class="view-title">年度締め設定</h6>
  <div class="divider-horizontal"></div>
  <div class="setting-item-wrapper">
    <div class="left-part">
      <label>年度締め</label>
      <select [(ngModel)]="fiscalYearSetting.closingMonth" class="closing-month-select closing-select" name="selectedYear"
        (ngModelChange)="changeSelectedMonth($event)">
        <option *ngFor="let item of monthList" [selected]="fiscalYearSetting.closingMonth === item.value"
          [ngValue]="item.value">{{item.name}}月
        </option>
      </select>
    </div>
    <div class="right-part">
      <label>月度の締め日</label>
      <select [(ngModel)]="fiscalYearSetting.closingDate" class="closing-day-select closing-select" name="selectedYear"
        (ngModelChange)="changeSelectedDay($event)">
        <option *ngFor="let item of dayList" [selected]="fiscalYearSetting.closingDate === item.value" [ngValue]="item.value">
          {{item.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="divider-horizontal"></div>
  <div class="footer-action-area">
    <button class="action-btn action-btn-base" [disabled]="submitting" (click)="save()">
      <div *ngIf="submitting" class="loading"></div>
      設定を保存する</button>
  </div>
</div>

<app-confirmation-dialog
  #finalAnswerDialog
  [clearBtn]="true"
  okBtnTitle="変更する"
  clearBtnTitle="キャンセル"
  title="変更内容ご確認ください"
  (submit)="submit()">
  <div style="color: #424242;">この設定を変更すると過去データも全て再集計が必要となります。</div>
  <div style="color: #424242;">再集計処理には時間が掛かりますが変更しますか？</div>
  <div>※ 変更内容は翌日の最新の集計結果に反映されます</div>
</app-confirmation-dialog>

<app-confirmation-dialog
  #initialSettingAlert
  [clearBtn]="false"
  okBtnTitle="設定する"
  title="年度締め設定"
  (submit)="ok()">
  <div>売上見込み、回収見込みを表示する為に、自社の締め日（年度締め/月度締め）の設定を行ってください。</div>
</app-confirmation-dialog>

<app-confirmation-dialog
  #settingSuccessfully
  [clearBtn]="false"
  okBtnTitle="OK"
  title="設定完了"
  (submit)="ok()">
  <div>年度締め日の設定が完了いたしました。</div><br />
  <div>本日深夜に全データの再集計を行ない、</div>
  <div>明日の12:00以降に集計結果が表示されます。</div>
  <div>どうぞ楽しみにお待ちください！</div>
</app-confirmation-dialog>

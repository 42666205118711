export const AppConsts = {
  version: '1.0.0',
  expectedSalesHint: 'https://idea-place.jp/wordpress/wp-content/themes/idea-place/assets/pdf/micomel/micomel-manual.pdf#page=11',
  expectedcollectionHint: 'https://idea-place.jp/wordpress/wp-content/themes/idea-place/assets/pdf/micomel/micomel-manual.pdf#page=17',


  /** Tokenローカルストレージ保存キー */
  tokenStorageKey: 'MICOMEL_ACCOUNT_TOKEN',
  lastSelectedAppId: 'MICOMEL_LAST_SUB_APP',
  lastSelectedAmountUnit: 'MICOMEL_LAST_AMOUNT_UNIT',
  lastSelectedYearMonth: 'MICOMEL_LAST_YEAR_MONTH',
  lastSelectedSupplierCd: 'MICOMEL_LAST_SUPPLIER_CD',
  /** 金額単位のデフォルト値 */
  defaultAmountUnit: 1000,
  /** 金額の表示単位候補 */
  amountUnitItems: [
    { value: 1, name: '単位：円' },
    { value: 1000, name: '単位：千円' },
    // { value: 10000, name: '単位：万円' },
    // { value: 100000, name: '単位：十万円' },
    { value: 1000000, name: '単位：百万円' },
  ],
  /** 月 */
  months: [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
    { name: '6', value: 6 },
    { name: '7', value: 7 },
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
  ],
  /** 月の日数 */
  days: [
    { name: '1日', value: 1 },
    { name: '2日', value: 2 },
    { name: '3日', value: 3 },
    { name: '4日', value: 4 },
    { name: '5日', value: 5 },
    { name: '6日', value: 6 },
    { name: '7日', value: 7 },
    { name: '8日', value: 8 },
    { name: '9日', value: 9 },
    { name: '10日', value: 10 },
    { name: '11日', value: 11 },
    { name: '12日', value: 12 },
    { name: '13日', value: 13 },
    { name: '14日', value: 14 },
    { name: '15日', value: 15 },
    { name: '16日', value: 16 },
    { name: '17日', value: 17 },
    { name: '18日', value: 18 },
    { name: '19日', value: 19 },
    { name: '20日', value: 20 },
    { name: '21日', value: 21 },
    { name: '22日', value: 22 },
    { name: '23日', value: 23 },
    { name: '24日', value: 24 },
    { name: '25日', value: 25 },
    { name: '26日', value: 26 },
    { name: '27日', value: 27 },
    { name: '末日', value: 31 },
  ],
  monthSpanList: [
    { name: '3ヶ月', value: 3 },
    { name: '6ヶ月', value: 6 },
    { name: '12ヶ月', value: 12 },
  ],
  /** アクション */
  actions: {
    getSalesPlan: { cd: 'getSalesPlan', name: '売上計画取得' },
    getSelseExpected: { cd: 'getSelseExpected', name: '売上見込み取得' },
    getCollectionExpected: { cd: 'getCollectionExpected', name: '回収見込み取得' },
    getExpectedOffset: { cd: 'getExpectedOffset', name: '相殺見込み取得' },
    downloadPaymentDetailCsv: { cd: 'downloadPaymentDetailCsv', name: '支払い明細D/L' },
    downloadAcceptanceDifferenceCsv: { cd: 'downloadAcceptanceDifferenceCsv', name: '検収相違D/L' },
    updateFiscalYear: { cd: 'updateFiscalYear', name: '会計年度設定更新' },
    downloadSelseExpectedScv: { cd: 'downloadSelseExpectedScv', name: '売上見込みD/L' },
    downloadCollectionExpectedCsv: { cd: 'downloadCollectionExpectedCsv', name: '回収見込みD/L' },
    downloadMonthlyResultScv: { cd: 'downloadSelseExpectedScv', name: '日次推移D/L' },
  },

} as const

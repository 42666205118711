import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core'
import { RouterLink } from '@angular/router'
import { CommonModule } from '@angular/common'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { MatDividerModule } from '@angular/material/divider'
import { IgxIconModule, IgxButtonModule, IgxDialogComponent } from '@infragistics/igniteui-angular'

import { SideMenuService } from '@app/services/side-menu.service'
import { AccountService } from '@app/services/account.service'
import { ChooseDisplayCategoryComponent } from '@app/views/_components/choose-display-category/choose-display-category.component'
import { AppConsts } from '@app/configs/app.configs'
import { Subscription } from 'rxjs'
import { environment } from '@environments/environment'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IgxIconModule,
    IgxButtonModule,
    MatMenuModule,
    MatDividerModule,
    ChooseDisplayCategoryComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(ChooseDisplayCategoryComponent) private chooseDisplayCategoryDialog!: ChooseDisplayCategoryComponent

  @ViewChild('userMenuTrigger', {static: true}) userMenu! : MatMenuTrigger
  @ViewChild('helpMenuTrigger', {static: true}) helpMenu! : MatMenuTrigger

  userName = ''
  dialogOpened = false
  productNo = ''
  isDev = !environment.production
  currentAppTitle = ''

  private currentSubApp$: Subscription = new Subscription()
  constructor(
    private accountService: AccountService,
    private sideMenuService: SideMenuService,
  ) {
    this.userName = this.accountService.userName

    this.currentAppTitle = this.accountService.currentAppTitle
  }

  ngOnInit(): void {
    this.productNo = this.accountService.productNo
    this.currentSubApp$ = this.accountService.currentApp$.subscribe(appId => {
      this.productNo = this.accountService.productNo
      this.currentAppTitle = this.accountService.currentAppTitle
    })
  }

  clickSideMenuBtn(): void {
    this.sideMenuService.operateSwitch()
  }

  openChooseDisplayCategoryDialog(): void {
    this.chooseDisplayCategoryDialog.openDialog()
    // this.dialogOpened = true
    // this.chooseDisplayCategoryDialog.open()
  }

  logout(): void {
    this.accountService.logout()
  }

  enteredUserMenu: boolean = false
  userMenuTimeId: any = null
  openUserMenu(): void {
    this.userMenu.openMenu()
  }
  closeUserMenu(userMenu: any): void {
    userMenu.closeMenu()
    this.userMenuTimeId = null
    this.enteredUserMenu = false
  }
  outUserMenu(): void {
    if (this.enteredUserMenu) {
      this.userMenuTimeId = setTimeout(this.closeUserMenu, 200, this.userMenu)
    }
  }

  enterUserMenu(): void {
    this.enteredUserMenu = true
    if (this.userMenuTimeId) {
      clearTimeout(this.userMenuTimeId)
    }
  }


  enteredHelpMenu: boolean = false
  helpMenutimeId: any = null
  openHelpMenu(): void {
    this.helpMenu.openMenu()
  }
  closeHelpMenu(helpMenu: any): void {
    helpMenu.closeMenu()
    this.helpMenutimeId = null
    this.enteredHelpMenu = false
  }
  outHelpMenu(): void {
    if (this.enteredHelpMenu) {
      this.helpMenutimeId = setTimeout(this.closeHelpMenu, 200, this.helpMenu)
    }
  }
  enterHelpMenu(): void {
    this.enteredHelpMenu = true
    if (this.helpMenutimeId) {
      clearTimeout(this.helpMenutimeId)
    }
  }

  ngOnDestroy(): void {
    this.currentSubApp$.unsubscribe()
  }

}

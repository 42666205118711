import { Component, OnDestroy, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AccountService } from '@app/services/account.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { Router } from '@angular/router'
import { MatTabsModule } from '@angular/material/tabs'
import { SideMenuService } from '@app/services/side-menu.service'
import { Subscription } from 'rxjs'
import { isNullOrEmpty, succeeded } from '@app/helpers/utils.toolkit'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatNativeDateModule } from '@angular/material/core'
import { MatInputModule } from '@angular/material/input'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { AppConsts } from '@app/configs/app.configs'
import { MatTableModule } from '@angular/material/table'

@Component({
  selector: 'app-action-history',
  standalone: true,
  imports: [CommonModule, MatTabsModule, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule, FormsModule, MatButtonModule, MatCardModule, MatTableModule],
  templateUrl: './action-history.component.html',
  styleUrl: './action-history.component.scss',
  host: { class:'content-container-wrap'}
})
export class ActionHistoryComponent implements OnInit, OnDestroy {
  sideMenuOff = false
  loading = false

  currentTabIndex = 0
  fromDate: Date = new Date()
  toDate: Date = new Date()

  get currentFromDate(): string {
    const from = this.fromDate
    return `${from.getFullYear()}-${from.getMonth()+1}-${from.getDate()}`
  }
  get currentToDate(): string {
    const to = this.toDate
    return `${to.getFullYear()}-${to.getMonth()+1}-${to.getDate()}`
  }

  private logData: any[] = []
  tabData: any[] = []


  headerData: any[] = []
  bodyData: any[] = []

  private sideMenuSwitch$: Subscription
  constructor(
    private router: Router,
    private accountSvc: AccountService,
    private sideMenuSvc: SideMenuService,
    private micomelApiSvc: MicomelApiService,
  ) {
    this.sideMenuSwitch$ = this.sideMenuSvc.sideMenuSwitch$.subscribe(opened => {
      this.sideMenuOff = !opened
    })
  }

  displayedColumns: string[] = ['アクション名', '実行回数']

  ngOnInit(): void {
    const account = this.accountSvc.accountInfo
    const companyInfo = account.Organizations.find((x: any) => x.OrganizationTypeCd === 'organization_type-company')
    if (companyInfo.OrganizationName.includes('インターサーブ')) {

    } else {
      // 組織が「インターサーブ」に限定
      this.router.navigateByUrl('/')
    }

  }

  showActionLog(): void {
    this.getActionLog(this.currentFromDate, this.currentToDate)
  }

  downloadActionLogCsv(fileType: string = 'text/csv'): void {
    const fileName = `アクションログ_${this.currentFromDate}-${this.currentToDate}`
    this.micomelApiSvc.downloadActionLogCsv(this.currentFromDate, this.currentToDate)
      .subscribe({
        next: response => {
          this.downLoadFile(response, fileType, fileName)
        },
        error: err => {
          const msg = 'ダウンロード失敗しました。'
          console.log(msg)
        }
      }).add(() => {
        //
      })
  }

  private downLoadFile(data: any, type: string, filename: string) {
    let blob = new Blob([data], { type: type })
    let downLoadElem = document.createElement('a')
    downLoadElem.href = window.URL.createObjectURL(blob)
    if (filename) {
      downLoadElem.setAttribute('download', filename)
    }
    document.body.appendChild(downLoadElem)
    downLoadElem.click()
  }

  tabClick($event: any): void {
    console.log(`tabClick() -->> `, $event)
    this.currentTabIndex = $event.index
    this.setTabData(this.currentTabIndex)
  }

  setTabData(tabIndex: any): void {
    if (tabIndex === 0) {
      this.headerData = []
      this.headerData = this.logData.reduce((data: any[], obj: any) => {
        if (data.length) {
          const ret = data.find(x => x.name === obj.organizationName)
          if (!ret) {
            data.push({
              name: obj.organizationName,
              cd: obj.organizationCd
            })
          }
        } else {
          data.push({
            name: obj.organizationName,
            cd: obj.organizationCd
          })
        }
        return data
      }, [])


      // みたいアクションをここで追加
      const actions = [
        { ...AppConsts.actions.getSelseExpected },
        { ...AppConsts.actions.getCollectionExpected },
      ]

      this.bodyData = []
      this.headerData.forEach(header => {
        const target = this.logData.filter(x => x.organizationCd === header.cd)
        if (target && target.length) {
          actions.forEach(action => {
            const objs = target.filter(x => x.actionCd === action.cd)
            if (objs) {
              this.bodyData.push({
                organizationCd: header.cd,
                name: action.name,
                count: objs.length
              })
            }
          })
        }
      })


      console.log(`tab 1 headerData: `, this.headerData, this.currentTabIndex)
    } else if (this.currentTabIndex === 1) {

    } else if (this.currentTabIndex === 2) {

    }
  }



  private getActionLog(from: string, to: string): void {
    if (!isNullOrEmpty(from) && !isNullOrEmpty(to)) {
      this.loading = true
      this.micomelApiSvc.getActionLog(from, to).subscribe({
        next: response => {
          if (succeeded(response)) {
            this.logData = response.data
            this.setTabData(this.currentTabIndex)
            console.log(`getActionLog() -->> `, this.logData)
          }
          this.loading = false
        },
        error: err => {
          this.loading = false
        }
      })
    }
  }



  ngOnDestroy(): void {
    this.sideMenuSwitch$.unsubscribe()
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { IgxDialogComponent, IgxDialogModule } from '@infragistics/igniteui-angular'
import { FiscalYearCalendarService } from '@app/services/fiscal-year-calendar.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { AccountService } from '@app/services/account.service'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'
import { NotificationService } from '@app/services/notification.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-download-dialog',
  standalone: true,
  imports: [CommonModule, IgxDialogModule, FormsModule],
  templateUrl: './download-dialog.component.html',
  styleUrl: './download-dialog.component.scss'
})
export class DownloadDialogComponent implements OnInit, OnDestroy {
  @ViewChild('downloadDialog', { static: true })
  public downloadDialog!: IgxDialogComponent

  @Input() dialogTitle = ''
  @Input() downloadFileType = ''

  fileType = {
    paymentDetail: 'paymentDetail', // 支払明細
    acceptanceDifference: 'acceptanceDifference' // 検収相違
  }

  selectedYear = new Date().getFullYear()
  selectedMonth = new Date().getMonth() + 1
  /** 年度リスト */
  yearList: any[] = []
  /** 月リスト */
  monthList: readonly any[] = []

  currentAppId: string | null = null
  private currentApp$: Subscription = new Subscription()
  constructor(
    private accountSvc: AccountService,
    private micomelApiSvc: MicomelApiService,
    private notificationSvc: NotificationService,
    private fiscalYearCalendar: FiscalYearCalendarService,
  ) {
    this.currentAppId = this.accountSvc.currentAppId
  }

  ngOnInit(): void {
    this.yearList = this.fiscalYearCalendar.getYearList()
    this.monthList = this.fiscalYearCalendar.getMonths()

    this.currentApp$ = this.accountSvc.currentApp$.subscribe(subAppId => {
      this.currentAppId = subAppId
    })
  }

  openDialog(): void {
    this.downloadDialog.open()
  }

  changeSelectedYear(year: any): void {
    this.selectedYear = year
  }

  changeSelectedMonth(month: any): void {
    this.selectedMonth = month
  }

  confirm(): void {
    const fileName = `${this.downloadFileType}_${this.selectedYear}-${this.selectedMonth}`
    if (this.downloadFileType === this.fileType.paymentDetail) {
      // 支払明細 D/L
      this.downloadPaymentDetailCsv(fileName)
    } else {
      // 検収相違 D/L
      this.downloadAcceptanceDifferenceCsv(fileName)
    }
  }

  downloadPaymentDetailCsv(fileName: string, fileType: string = 'text/csv'): void {
    const supplierCode = this.accountSvc.currentSupplierCode
    this.micomelApiSvc.downloadPaymentDetailCsv(supplierCode, this.selectedYear, this.selectedMonth)
      .subscribe({
        next: response => {
          this.downLoadFile(response, fileType, fileName)
        },
        error: err => {
          const msg = 'ダウンロード失敗しました。'
          this.notificationSvc.showNotification(msg, false)
        }
      }).add(() => {
        //
      })
  }
  downloadAcceptanceDifferenceCsv(fileName: string, fileType: string = 'text/csv'): void {
    const supplierCode = this.accountSvc.currentSupplierCode
    this.micomelApiSvc.downloadAcceptanceDifferenceCsv(supplierCode, this.selectedYear, this.selectedMonth)
      .subscribe({
        next: response => {
          this.downLoadFile(response, fileType, fileName)
        },
        error: err => {
          const msg = 'ダウンロード失敗しました。'
          this.notificationSvc.showNotification(msg, false)
        }
      }).add(() => {
        //
      })
  }

  cancel(): void {
    this.downloadDialog.close()
  }

  downLoadFile(data: any, type: string, filename: string) {
    let blob = new Blob([data], { type: type })
    let downLoadElem = document.createElement('a')
    downLoadElem.href = window.URL.createObjectURL(blob)
    if (filename) {
      downLoadElem.setAttribute('download', filename)
    }
    document.body.appendChild(downLoadElem)
    downLoadElem.click()
    this.downloadDialog.close()
  }

  ngOnDestroy(): void {
    this.currentApp$.unsubscribe()
  }
}

import { DecimalPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { isNullOrEmpty, valIsString } from '@app/helpers/utils.toolkit'
/**
 * 自前カスタムパイプ Input要素の桁区切り
 */
@Pipe({ name: 'numberInput', standalone: true })
export class NumberInputPipe implements PipeTransform {

  transform(val: any, digits?: string): string {
    return isNullOrEmpty(val) || isNaN(Number(val)) ?
      '' : new DecimalPipe('ja').transform(valIsString(val) ? val.trim() : val, digits) ?? ''
  }

  parse(val: string): string {
    return val.replace(/,/g,'')
  }
}

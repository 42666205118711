<div class="action-area-wrapper">
  <div class="upper-section">
    <h6 class="view-title">{{currentViewTitle}}
      <a title="各項目の説明はこちら" [href]="hintUrl" class="help-btn" target="_blank">?</a></h6>
    <!-- <div class="segment-selector">
      <button igxButton="outlined" [routerLink]="[salesForecast]"
        [class.active]="currentView === salesForecast"
        (click)="changeView(salesForecast)"
        class="segment-selector-btn left-btn active-btn" igxRipple>売上</button>
      <button igxButton="outlined" [routerLink]="[salesCollectionProspects]"
        [class.active]="currentView === salesCollectionProspects"
        (click)="changeView(salesCollectionProspects)"
        class="segment-selector-btn right-btn" igxRipple>回収</button>
    </div> -->
  </div>
  <div class="middle-section">
    <select [(ngModel)]="selectedYear" class="fiscal-year-select normal-select" name="selectedYear" (ngModelChange)="changeSelectedYear($event)">
      <option *ngFor="let item of yearList" [selected]="selectedYear === item.value" [ngValue]="item.value">{{item.name}}
      </option>
    </select>
    <label>年 </label>
    <select [(ngModel)]="selectedMonth" class="fiscal-year-select" name="selectedMonth" (ngModelChange)="changeSelectedMonth($event)">
      <option *ngFor="let item of monthList" [selected]="selectedMonth === item.value" [ngValue]="item.value">{{item.name}}
      </option>
    </select>
    <label>月</label>
    <button class="action-btn get-data-btn" (click)="clickShowBtn()">表示</button>

  </div>
  <div class="lower-section">
    <span>
      <span class="update-date-title">最終更新日時：</span><span class="update-datetime">{{updatedDate | date:"yyyy年MM月dd日 HH時mm分ss秒"}}</span>
    </span>
    <span>
      <select [ngModel]="amountUnit" name="selectedYear" class="normal-select" (ngModelChange)="changeAmountUnit($event)">
        <option *ngFor="let item of amountUnitItems" [selected]="item.value === amountUnit" [ngValue]="item.value">{{item.name}}</option>
      </select>
    </span>
  </div>

</div>
<div #tooltipRef="tooltip" igxTooltip style="padding: 16px;">
  売上見込みの説明文。。。
</div>

<div class="content-container" [class.side-menu-off]="sideMenuOff">

  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [(ngModel)]="fromDate">
      <input matEndDate placeholder="End date" [(ngModel)]="toDate">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <button style="margin: auto 16px;" (click)="showActionLog()" mat-stroked-button>表示</button>

  <button (click)="downloadActionLogCsv()" mat-stroked-button>アクション履歴ダウンロード(CSV)</button>

  <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
    <mat-tab label="企業別">
      <ng-container *ngIf="currentTabIndex === 0">
        <mat-card *ngFor="let item of headerData">
          <mat-card-header>
            <mat-card-title>
              <label>企業名：{{item.name}}</label>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table>
              <thead>
                <th>アクション名</th>
                <th>実行回数</th>
              </thead>
              <tbody>
                <ng-container *ngFor="let row of bodyData">
                  <ng-container *ngIf="row.organizationCd === item.cd">
                    <tr>
                      <td>{{row.name}}</td>
                      <td class="number-cell">{{row.count}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </mat-card-content>





          <!-- <mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
          </mat-card-actions> -->
        </mat-card>
      </ng-container>
    </mat-tab>
    <mat-tab label="アクション別">
      <ng-container *ngIf="currentTabIndex === 1">アクション別 実装待ち</ng-container>
    </mat-tab>
    <mat-tab label="時間帯別">
      <ng-container *ngIf="currentTabIndex === 2">時間帯別 実装待ち</ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

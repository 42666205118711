import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Router, RouterOutlet } from '@angular/router'
import { isNullOrEmpty, succeeded } from '@app/helpers/utils.toolkit'
import { AccountService } from '@app/services/account.service'
import { HeaderComponent } from '@app/views/layout/header/header.component'
import { IsbeAuthComponent } from '@app/views/isbe-auth/isbe-auth.component'
import { MicomelBackendService } from '@app/services/micomel-backend.api.service'
import { SideMenuComponent } from '@app/views/layout/side-menu/side-menu.component'
import { AppConsts } from './configs/app.configs'
import { LoginIFrame } from './helpers/virtual.data'
import { GlobalSnackbarComponent } from './views/_components/global-snackbar/global-snackbar.component'
import { CheckServerUpdateComponent } from './views/_components/check-server-update/check-server-update.component'
import { NotificationService } from './services/notification.service'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, SideMenuComponent, IsbeAuthComponent, GlobalSnackbarComponent,
    CheckServerUpdateComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  account?: any | null
  isLoggedIn = false
  loadingMsg = 'ログイン処理中'
  constructor(
    private router: Router,
    private accountSvc: AccountService,
    private notificationSvc: NotificationService,
    private micomelBackend: MicomelBackendService
  ) {
    const msg = 'ログイン失敗しました。'
    this.accountSvc.account$.subscribe(account => {
      if (account) {
        const customerCd = account.CustomerCd
        this.micomelBackend.getApplicationInfo(customerCd)
          .subscribe({
            next: response => {
              if (succeeded(response)) {
                this.accountSvc.applications = response.data
                this.isLoggedIn = true
                if (!this.checkfiscalYearSettingVal()) {
                  this.router.navigate(['/settings/fiscalYear'])
                }
              } else {
                // TODO: Show Error Msg
                this.notificationSvc.showNotification(msg, false)
              }
            },
            error: err => {
              this.notificationSvc.showNotification(msg, false)
            }
          }).add(() => {
            //
          })
      } else {
        // ログアウト
        this.isLoggedIn = false
        this.accountSvc.applications = null
      }
    })


    /** オフライン */
    // const account = LoginIFrame.Data[0]
    // this.accountService.accountInfo = account
    /** オフライン */
  }

  /**
   * アプリ選択して会計年度未設定の場合　設定画面へ
   * @returns
   */
  private checkfiscalYearSettingVal(): boolean {
    const fiscalYearSetting = this.accountSvc.fiscalYearSetting
    if (!isNullOrEmpty(fiscalYearSetting?.closingDate) && !isNullOrEmpty(fiscalYearSetting?.closingDate)) {
      return true
    }
    return false
  }
}

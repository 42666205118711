import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppConsts } from '@app/configs/app.configs'
import { AccountService } from '@app/services/account.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { FormsModule } from '@angular/forms'
import { cloneObj, isNullOrEmpty, strIns, succeeded } from '@app/helpers/utils.toolkit'
import { NotificationService } from '@app/services/notification.service'
import { createCsvData, formatDate } from '../_helpers/view.helpers'
import { CsvFileTypes, IgxCsvExporterOptions, IgxCsvExporterService } from '@infragistics/igniteui-angular'
import { BottomBarComponent } from '../_components/bottom-bar/bottom-bar.component'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-monthly-result',
  standalone: true,
  imports: [CommonModule, FormsModule, BottomBarComponent],
  templateUrl: './monthly-result.component.html',
  styleUrl: './monthly-result.component.scss',
  host: { class: 'content-container-wrap' }
})
export class MonthlyResultComponent implements OnInit {
  loading = false
  originalData: any = null
  gridData: any[] = []
  currentUnit: number
  amountUnitItems: readonly any[]
  updatedDate: Date | null = null
  targetYear: string = ''
  dataType = ''
  targetMonth = ''
  subTitle = ''

  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private router: Router,
    private accountSvc: AccountService,
    private activatedRoute: ActivatedRoute,
    private micomelApiSvc: MicomelApiService,
    private csvExportSvc: IgxCsvExporterService,
    private notificationSvc: NotificationService,
  ) {
    this.currentUnit = this.accountSvc.amountUnit
    this.amountUnitItems = AppConsts.amountUnitItems
  }

  ngOnInit(): void {
    const originalData = this.accountSvc.expectedSalesViewOriginalData

    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe(appId => {
      // this.clearGrid()
      // this.currentAppId = appId
      // this.currentAppTitle = this.accountSvc.currentAppTitle
      this.router.navigateByUrl('/expected/sales')
    })


    // 詳細取得
    this.activatedRoute.params.subscribe((params: Params) => {
      this.showDetailView(params)
    })

    // TODO: ここは相談してから
    // if (originalData && originalData.length) {
    //   // 詳細取得
    //   this.activatedRoute.params.subscribe((params: Params) => {
    //     this.showDetailView(params)
    //   })
    // } else {
    //   // 更新した場合、売上見込みに戻す
    //   this.router.navigateByUrl('/expected/sales')
    // }
  }

  private showDetailView(params: Params): void {
    this.loading = true
    const { dataType, targetMonth } = params as { dataType: string, targetMonth: string }
    this.dataType = dataType
    this.targetMonth = targetMonth
    if (this.dataType === 'contract') {
      // contract 先付実績
      this.subTitle = '先付実績'
    } else if (this.dataType === 'order') {
      // order A伝実績
      this.subTitle = 'A伝実績'
    } else {
      // shipment 出荷実績
      this.subTitle = '出荷実績'
    }

    const supplierAppId = this.accountSvc.applicationId // サプライヤーAppId
    this.micomelApiSvc.getSingleFiscalMonthInterval(supplierAppId, targetMonth).subscribe({
      next: (response) => {
        try {
          if (succeeded(response)) {
            const ret = response.data
            const dateFrom = formatDate(ret.dateFrom)
            const dateTo = formatDate(ret.dateTo)
            const dateObj = new Date(ret.dateFrom)
            this.targetYear = `${dateObj.getFullYear()}年`
            console.log(`getSingleFiscalMonthInterval() ->> `, response.data)
            const appIds = this.accountSvc.getCurrentAppIds
            this.getSelseExpectedDetail(dateFrom, dateTo, dataType, appIds)
          }
        } catch (error) {

        }
      },
      error: (err) => {

      },
      complete: () => {

      }
    })
  }

  private getSelseExpectedDetail(periodFrom: string, periodTo: string, dataType: string, payload: string[]): void {
    this.micomelApiSvc.getSelseExpectedDetail(periodFrom, periodTo, dataType, payload).subscribe({
      next: (response) => {
        try {
          if (succeeded(response)) {
            this.originalData = cloneObj(response.data)
            this.gridData = response.data.values
            this.updatedDate = response.data.updatedDatetime
            this.convertUnitNoTruncation(this.gridData, this.currentUnit, false)
          }
        } catch (error) {

        }
      },
      error: (err) => {

      },
      complete: () => {

      }
    })
  }

  clickBackButton(obj: any): void {
    this.accountSvc.lockOriginalData = true
    this.router.navigateByUrl('/expected/sales')
  }

  exportCsv(): void {
    if (!this.gridData || !this.gridData.length) {
      this.notificationSvc.showNotification('表示してからダウンロードしてください', false)
      return
    }

    const fileName = `日次推移_${this.subTitle}_${this.targetMonth}`
    const data = cloneObj(this.originalData.values)
    const csvData = this.createCsvData(data)

    const opt: IgxCsvExporterOptions = new IgxCsvExporterOptions(fileName, CsvFileTypes.CSV);
    this.csvExportSvc.exportData(csvData, opt)

    // アクションログ送信
    this.micomelApiSvc.sendActionLog(AppConsts.actions.downloadMonthlyResultScv)
  }

  private createCsvData(gridData: any[]): any[] {
    const data: any[] = []
    if (gridData && gridData.length) {
      gridData.forEach(obj => {
        let item: any = {}
        item[this.targetYear] = `${formatDate(obj.date)} (${obj.dayOfWeek})`
        item['実績'] = obj.cumulativeValue
        item['前日差額'] = obj.value
        data.push(item)
      })
      return data
    }
    return []
  }


  changeAmountUnit(val: any): void {
    const data = cloneObj(this.originalData.values)
    this.gridData = data
    // this.convertUnitNoTruncation(this.gridData, this.currentUnit, true)
    this.convertUnitNoTruncation(this.gridData, val, false)
    this.currentUnit = val
  }

  // 単位変換
  private convertUnitNoTruncation(convertData: any[], currentUnit: number, fullAmount: boolean): void {
    if (!Array.isArray(convertData)) return

    convertData.forEach((obj: any) => {
      let val = obj.value
      // 変換不要やできないものは除外
      if (!isNullOrEmpty(val) && val !== 0 && !isNaN(Number(val))) {
        if (fullAmount) {
          // 保存など、元の数字(通常単位：1円)に戻す　小数点以下切り捨て
          obj.value = Number(val) * currentUnit
        } else {
          // 表示/編集など、指定単位に変換  小数点以下切り捨て
          obj.value = Math.round(Number(val) / currentUnit)
        }
      }

      let cumulativeValue = obj.cumulativeValue
      if (!isNullOrEmpty(cumulativeValue) && cumulativeValue !== 0 && !isNaN(Number(cumulativeValue))) {
        if (fullAmount) {
          // 保存など、元の数字(通常単位：1円)に戻す　小数点以下切り捨て
          obj.cumulativeValue = Number(cumulativeValue) * currentUnit
        } else {
          // 表示/編集など、指定単位に変換  小数点以下切り捨て
          obj.cumulativeValue = Math.round(Number(cumulativeValue) / currentUnit)
        }
      }
    })
  }

  widthVal(): string {
    if (this.currentUnit === 1) {
      return 'col-3'
    } else if (this.currentUnit === 1000) {
      return 'col-6'
    } else if (this.currentUnit === 1000000) {
      return 'col-12'
    }
    return 'col-6'
  }
}

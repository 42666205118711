import { Component, HostListener, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-check-server-update',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './check-server-update.component.html',
  styleUrl: './check-server-update.component.scss'
})
export class CheckServerUpdateComponent implements OnInit {
  private previousHash = null
  public isChanged = false
  private count = 10;
  public msg = 'アプリケーションの更新を確認しました。' + this.count + '秒後この画面をリロードします'
  private maintenanceMsg = 'アプリケーションのメンテナンスを開始します。10秒後この画面メンテナンスモードに切り替えます。'
  public btnMsg = '更新'
  private maintenanceBtnMsg = '切り替え'
  private maintenanceMode = false

  private timeLimit = 60000 // 60秒
  private reloadTimeLimit = 10000 // 10秒
  private activeMode = true
  private timerId: any = null

  @HostListener('window:blur', ['$event']) onBlur(event: Event) {
    this.activeMode = false
    this.stopVertionCheck()
  }
  @HostListener('window:focus', ['$event']) onFocus(event: Event) {
    this.activeMode = true
    this.startVertionCheck()
  }

  ngOnInit(): void {
    this.fetchServer('force-cache')
    this.fetchServer()
    this.startVertionCheck()
  }

  private startVertionCheck(): void {
    this.stopVertionCheck()
    this.timerId = setInterval(this.fetchServer, this.timeLimit)
  }

  private stopVertionCheck(): void {
    clearInterval(this.timerId)
  }
  //サーバーからIndexHtmlを取得
  private fetchServer = (requestCache:RequestCache = 'reload'): void => {
    try {
      fetch('./index.html', { cache: requestCache })
        .then(res => {
          if (res.status === 302) {
            // 問題あり、キャッチできない
            this.maintenanceMode = true
            return res.text()
          } else if (res.status !== 200) {
            throw Error('Cannot access server.')
          }
          return res.text()
        })
        .then(html => {
          if (this.maintenanceMode) {
            this.showMaintenanceMsg()
          } else {
            const hash = this.createHash(html)
            this.judgeHash(hash)
          }
        })
        .catch(err => console.log(err))
    } catch (error) {
      // Log吐くのみ
      console.log(error)
    }
  }

  private showMaintenanceMsg(): void {
    this.msg = this.maintenanceMsg
    this.btnMsg = this.maintenanceBtnMsg
    this.isChanged = true
    setTimeout(this.reload, this.reloadTimeLimit)
  }

  private createHash(str: any): any {
    const len = str.length
    let hash = 0
    if (len === 0) { return hash }
    let i
    for (i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + str.charCodeAt(i)
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  private judgeHash(hash: any): void {
    if (!this.previousHash) {
      this.previousHash = hash
      return
    }
    if (this.previousHash !== hash) {
      this.isChanged = true
      setTimeout(this.reload, this.reloadTimeLimit)
    }
  }
  //リロード
  reload(): void {
    location.reload()
  }
}

import { Routes } from '@angular/router'
import { SalesComponent } from '@app/views/expected/sales/sales.component'
import { CollectionComponent } from '@app/views/expected/collection/collection.component'
import { SalesPlanComponent } from '@app/views/expected/sales-plan/sales-plan.component'
import { FiscalYearComponent } from '@app/views/settings/fiscal-year/fiscal-year.component'
import { ExpectedOffsetComponent } from '@app/views/settings/expected-offset/expected-offset.component'
import { authGuard } from './helpers/auth.guard'
import { MonthSpanComponent } from '@app/views/settings/month-span/month-span.component'
import { ActionHistoryComponent } from './views/action-log/action-history/action-history.component'
import { MonthlyResultComponent } from './views/expected/monthly-result/monthly-result.component'

export const routes: Routes = [
  /** トップページ *今はないので、売上見込みにリダイレクト */
  { title: 'トップページ', path: '', redirectTo: '/expected/sales', pathMatch: 'full' },
  /** 売上見込み */
  { title: '売上見込み', path: 'expected/sales', component: SalesComponent },
  /** 日次推移：先付実績 */
  { title: '日次推移：先付実績', path: 'expected/monthlyResult/:dataType/:targetMonth', component: MonthlyResultComponent },
  /** 回収見込み */
  { title: '回収見込み', path: 'expected/collection', component: CollectionComponent },
  /** 売上計画入力（事業別） */
  { title: '売上計画入力', path: 'expected/salesPlan', component: SalesPlanComponent },
  /** 設定: 年度締め設定 */
  { title: '年度締め設定', path: 'settings/fiscalYear', component: FiscalYearComponent },
  /** 設定: 相殺見込み設定 */
  { title: '相殺見込み設定', path: 'settings/expectedOffset', component: ExpectedOffsetComponent },
  /** 設定: 表示設定 */
  { title: '表示設定', path: 'settings/monthSpan', component: MonthSpanComponent },
  /** アクションログ表示 */
  { title: 'アクションログ', path: 'action-history', component: ActionHistoryComponent },
  { path: '**', redirectTo: '/' },
]

import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'

@Injectable({ providedIn: 'root' })
export class MicomelBackendService {
  private micomeBackendlUrl = environment.micomelBackendUrl

  constructor(
    private http: HttpClient
  ) { }

  /**
   * アプリ契約情報取得
   * @param customerCd
   * @returns
   */
  getApplicationInfo(customerCd: string): Observable<any> {
    const url = `${this.micomeBackendlUrl}/api/Application/${customerCd}`
    return this.http.get<any>(url)
  }

}

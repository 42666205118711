import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { AppConsts } from '@app/configs/app.configs'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { AccountService } from '@app/services/account.service'
import { NotificationService } from '@app/services/notification.service'
import { succeeded } from '@app/helpers/utils.toolkit'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-month-span',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './month-span.component.html',
  styleUrl: './month-span.component.scss',
  host: { class:'content-container-wrap'}
})
export class MonthSpanComponent implements OnInit, OnDestroy {
  submitting = false
  monthSpanList = AppConsts.monthSpanList
  monthSpan: number
  private applicationId: string | null = null

  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private accountSvc: AccountService,
    private notificationSvc: NotificationService,
    private micomelSvc: MicomelApiService,
  ) {
    this.applicationId = this.accountSvc.applicationId
    this.monthSpan = this.accountSvc.monthSpan
  }

  ngOnInit(): void {
    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe(appId => {
      this.applicationId = this.accountSvc.applicationId
      this.monthSpan = this.accountSvc.monthSpan
    })
  }


  changeMonthSpan(month: any): void {
    // this.selectedMonth = month
  }

  save(): void {
    console.log(this.monthSpan)
    this.submitting = true
    const monthSpan = { monthSpan :this.monthSpan }
    this.micomelSvc.updateMonthSpan(this.applicationId!, monthSpan).subscribe({
      next: (response: any) => {
        const msg = '表示設定を保存しました'
        if (succeeded(response, false, true)) {
          this.notificationSvc.showNotification(msg, false)
          this.accountSvc.monthSpan = this.monthSpan
        } else {
          // 異常
          this.notificationSvc.showNotification(response?.resultMessage, false)
        }
      },
      error: (err: any) => {
        console.log(`registerSalesPlan() =>> Error`, err)
        const msg = '表示設定の保存が失敗しました。'
        this.notificationSvc.showNotification(msg, false)
      },
      complete: () => {
        this.submitting = false
      }
    }).add(() => {
      this.submitting = false
    })
  }

  ngOnDestroy(): void {
    this.currentSubApp$.unsubscribe()
  }
}

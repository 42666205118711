<div class="content-container">
  <h6 class="view-title">相殺見込み設定</h6>
  <div class="header-action-bar-area">
    <div class="action-frame"><label class="fiscal-year-title">年度</label>
      <select [(ngModel)]="targetYear" class="fiscal-year-select normal-select" name="selectedYear" (ngModelChange)="changeSelectedYear($event)">
        <option *ngFor="let item of yearList" [selected]="targetYear === item.value" [ngValue]="item.value">{{item.name}}
        </option>
      </select>
      <button class="action-btn get-data-btn" (click)="showExpectedOffset()">表示</button>
    </div>

    <div class="amount-unit">
      <select [ngModel]="currentUnit" name="selectedUnit" class="normal-select" (ngModelChange)="changeAmountUnit($event)">
        <option *ngFor="let item of amountUnitItems" [selected]="item.value === currentUnit" [ngValue]="item.value">{{item.name}}</option>
      </select></div>
  </div>
  <div style="width: 100%;margin: 16px auto;">
    <div class="grid-area" *ngIf="gridData && gridData.length && !loading; else elseBlock">
      <app-editable-grid
        [gridData]="gridData"
        [headerData]="headerData"
        [rowInfo]="rowInfo"
        [currentUnit]="currentUnit"
      >
      </app-editable-grid>
    </div>
    <ng-template #elseBlock>
      <div class="skeleton-block">
        <div class="skeleton-msg" *ngIf="!loading; else elseBlock">「表示」ボタンをクリックしてください</div>
        <ng-template #elseBlock>
          <div class="skeleton-msg">Loading...</div>
        </ng-template>
      </div>
    </ng-template>


  </div>
  <div class="footer-action-area">
    <button class="action-btn action-btn-base" [disabled]="submitting" (click)="updateExpectedOffset()">
      <div *ngIf="submitting" class="loading"></div>登録</button>
  </div>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FiscalYearCalendarService } from '../../../services/fiscal-year-calendar.service'
import { FormsModule } from '@angular/forms'

import { isNullOrEmpty, succeeded } from '@app/helpers/utils.toolkit'
import { ConfirmationDialogComponent } from '@app/views/_components/confirmation-dialog/confirmation-dialog.component'
import { AccountService } from '@app/services/account.service'
import { NotificationService } from '@app/services/notification.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-fiscal-year',
  standalone: true,
  imports: [CommonModule, FormsModule, ConfirmationDialogComponent],
  templateUrl: './fiscal-year.component.html',
  styleUrl: './fiscal-year.component.scss',
  host: { class:'content-container-wrap'}
})
export class FiscalYearComponent implements OnInit, OnDestroy {
  // @ViewChild(ConfirmationDialogComponent)
  // private confirmationDialog!: ConfirmationDialogComponent

  @ViewChild('finalAnswerDialog', { static: true })
  public finalAnswerDialog!: ConfirmationDialogComponent

  @ViewChild('initialSettingAlert', { static: true })
  public initialSettingAlert!: ConfirmationDialogComponent

  @ViewChild('settingSuccessfully', { static: true })
  public settingSuccessfully!: ConfirmationDialogComponent

  firstSetting: boolean = false
  submitting = false
  private applicationId: string | null = null
  fiscalYearSetting: any = {
    closingMonth: null,
    closingDate: null
  }

  /** 日付リスト */
  dayList: readonly any[] = []
  /** 月リスト */
  monthList: readonly any[] = []

  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private accountSvc: AccountService,
    private notificationSvc: NotificationService,
    private micomelSvc: MicomelApiService,
    private fiscalYearCalendar: FiscalYearCalendarService,
  ) {
    this.applicationId = this.accountSvc.applicationId

    this.accountSvc.yesShowMe = false
    if (this.accountSvc?.fiscalYearSetting) {
      this.fiscalYearSetting = this.accountSvc.fiscalYearSetting
    }

    this.dayList = this.fiscalYearCalendar.getDays()

    this.monthList = this.fiscalYearCalendar.getMonths()

    this.firstSetting = this.noSetting
  }

  ngOnInit(): void {
    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe(appId => {
      this.applicationId = this.accountSvc.applicationId
      if (this.accountSvc?.fiscalYearSetting) {
        this.fiscalYearSetting = this.accountSvc.fiscalYearSetting
      }
    })

    if (this.noSetting) {
      this.initialSettingAlert.openDialog()
      this.accountSvc.warningUnconfirmed = false // 警告を確認したことに、次回ログインまで表示しないように
    }
  }

  changeSelectedMonth(month: any): void {
    //
  }


  changeSelectedDay(month: any): void {
    // this.selectedMonth = month
    console.log(`${this.constructor.name}.changeSelectedDay()`)
  }

  save(): void {
    if (isNullOrEmpty(this.fiscalYearSetting?.closingMonth)) {
      this.notificationSvc.showNotification('年度締めを選択してください', false)
      return
    } else if (isNullOrEmpty(this.fiscalYearSetting?.closingDate)) {
      this.notificationSvc.showNotification('月度締めを選択してください', false)
      return
    }
    if (this.firstSetting) {
      this.submit()
    } else {
      this.finalAnswerDialog.openDialog()
    }
  }

  submit(): void {
    this.submitting = true
    const fiscalYearSetting = {
      closingMonth: this.fiscalYearSetting.closingMonth,
      closingDate: this.fiscalYearSetting.closingDate
    }
    this.micomelSvc.updateFiscalYear(this.applicationId!, fiscalYearSetting).subscribe({
      next: (response: any) => {
        if (succeeded(response, false, true)) {
          this.accountSvc.fiscalYearSetting = this.fiscalYearSetting
          this.settingSuccessfully.openDialog()
        } else {
          // 異常
          this.notificationSvc.showNotification(response?.resultMessage, false)
        }
      },
      error: (err: any) => {
        console.log(`registerSalesPlan() =>> Error`, err)
        const msg = '年度締め設定の保存が失敗しました。'
        this.notificationSvc.showNotification(msg, false)
      },
      complete: () => {
        this.submitting = false
      }
    }).add(() => {
      this.submitting = false
    })
  }

  ok(): void {
    //
  }

  get noSetting(): boolean {
    if (isNullOrEmpty(this.fiscalYearSetting) ||
      isNullOrEmpty(this.fiscalYearSetting.closingMonth) ||
      isNullOrEmpty(this.fiscalYearSetting.closingDate)) {
      return true
    }
    return false
  }

  ngOnDestroy(): void {
    this.currentSubApp$.unsubscribe()
  }
}

import { Injectable } from '@angular/core'
import { AppConsts } from '@app/configs/app.configs'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'

/**
 * 年度関連のサービスを提供
 * ・最後選択した年度の取得/保持
 * ・年度リストの取得
 */
@Injectable({ providedIn: 'root' })
export class FiscalYearCalendarService {

  constructor() { }

  /**
   * 年度リスト取得
   *   デフォルト：5年前から5年後までの計11年分
   * @param hasYear {年}あり/なし指定
   * @param severalYears [{何年前か指定}, {何年後か指定}] default: [5,5]
   * @returns
   */
  getYearList(hasYear: boolean = false, severalYears: number[] = [5, 5]): any[] {
    const objs = []
    const str = hasYear ? '年' : ''
    const year = this.getCurrentYearVal() - severalYears[0]
    const len = severalYears.reduce((sum, current) => sum + current, 1)
    for (let i = 0; i < len; i++) {
      objs.push({ name: `${year + i}${str}`, value: year + i })
    }
    return objs
  }

  /**
   * 月リスト取得
   * @returns
   */
  getMonths(): readonly any[] {
    return AppConsts.months
  }

  /**
   * 年度取得
   * @param viewKey
   * @returns
   */
  getSelectedYear(viewKey: string): number {
    return this.getYearMonthObj(viewKey).year
  }

  /**
   * 月度取得
   * @param viewKey
   * @returns
   */
  getSelectedMonth(viewKey: string): number {
    return this.getYearMonthObj(viewKey).month
  }

  /**
   * 年月Object取得
   * @param viewKey
   * @returns
   */
  getYearMonthObj(viewKey: string): any {
    const key = `${AppConsts.lastSelectedYearMonth}_${viewKey}`
    const val = this.getStorageVal(key)
    if (!isNullOrEmpty(val)) {
      const obj = JSON.parse(val!)
      if (obj.year !== undefined && obj.month !== undefined) {
        return obj
      }
    }
    return { year: this.getCurrentYearVal(), month: this.getCurrentMonthVal()}
  }

  /**
   * 月リスト取得
   * @returns
   */
  getDays(): readonly any[] {
    return AppConsts.days
  }

  /**
   * 年月を保持
   * @param viewKey
   * @param yearVal
   * @param monthVal
   */
  setYearMonthVal(
    viewKey: string,
    yearVal: string | number,
    monthVal: string | number = ''): void {
    const key = `${AppConsts.lastSelectedYearMonth}_${viewKey}`
    const obj = { year: yearVal, month: monthVal }
    localStorage.setItem(key, JSON.stringify(obj))
  }

  /** 今の年度取得 */
  private getCurrentYearVal(): number {
    return new Date().getFullYear()
  }

  /** 今の月度取得 */
  private getCurrentMonthVal(): number {
    return new Date().getMonth() + 1
  }

  /** ストレージから値を取得 */
  private getStorageVal(key: string): string | null {
    return isNullOrEmpty(key) ? null : localStorage.getItem(key)
  }
}


import { cloneObj, isNullOrEmpty, strIns } from "@app/helpers/utils.toolkit"

/**
 * 単位変換
 * @param obj
 * @returns
 */
export const convertUnit = (convertData: any[], currentUnit: number): any[] => {
  const targetValueType = 'amount'
  if (!Array.isArray(convertData)) return []
  convertData.forEach((category: any) => {
    if (!isNullOrEmpty(category) && Array.isArray(category.values)) {
      category.values.forEach((row: any) => {
        if (!isNullOrEmpty(row) && !isNullOrEmpty(row.values) && row.valueType === targetValueType) {
          const columns = Object.entries(row.values)
          columns.forEach(col => {
            var val = col[1]
            if (!isNullOrEmpty(val) && val !== 0 && !isNaN(Number(val))) {
              col[1] = Math.round(Number(val) / currentUnit)
            }
          })
          row.values = Object.fromEntries(columns)
        }
      })
    }
  })
  return convertData
}

/**
 * 金額のValuesを複製 values => originalValues
 * @param convertData
 * @returns
 */
export const createOriginalValues = (convertData: any[]): any[] => {
  const targetValueType = 'amount'
  if (!Array.isArray(convertData)) return []
  convertData.forEach((category: any) => {
    if (!isNullOrEmpty(category) && Array.isArray(category.values)) {
      category.values.forEach((row: any) => {
        if (!isNullOrEmpty(row) && !isNullOrEmpty(row.values) && row.valueType === targetValueType) {
          row.originalValues = cloneObj(row.values)
        }
      })
    }
  })
  return convertData
}


/**
 * 生成Headerデータ
 * @param startYear
 * @param startMonth
 * @param period
 * @returns
 */
export const createHeaderData = (startYear: number, startMonth: number, period: number = 6): any[] => {
  let ret = []
  ret.push({ title: '', value: null, tooltip: '' }) // 先頭空白列
  for (let i = 0; i < period; i++) {
    const startDate = new Date(`${startYear}-${startMonth}-1`)
    startDate.setMonth(startDate.getMonth() + i)
    const targetYear = startDate.getFullYear()
    const targetMonth = startDate.getMonth() + 1
    const element = {
      title: `${targetMonth}月`,
      value: Number(targetYear + targetMonth.toString().padStart(2, '0')),
      tooltip: `${targetYear}年${targetMonth}月`
    }
    ret.push(element)
  }
  return ret
}
/**
 *
 * @param yearVal
 * @param monthVal
 * @param add
 * @returns
 */
export const getYearMonth = (yearVal: number, monthVal: number): number => {
  return Number(yearVal + monthVal.toString().padStart(2, '0'))
}

export const addMonthSpan = (year: number, month: number, add: number): number => {
  const temp = new Date(`${year}-${month}-01`)
  temp.setMonth(temp.getMonth() + (add - 1))
  const toYear = temp.getFullYear()
  const toMonth = temp.getMonth() + 1
  return Number(toYear + toMonth.toString().padStart(2, '0'))
}

export const formatDate = (dateString: string): string => {
  const dateObj = new Date(dateString)
  const yearVal = dateObj.getFullYear()
  const monthVal = dateObj.getMonth() + 1
  const dayVal = dateObj.getDate()
  return `${yearVal}-${monthVal.toString().padStart(2, '0')}-${dayVal.toString().padStart(2, '0')}`
}

/**
 * CSVデータを出力
 * @param exportData
 * @returns
 */
export const createCsvData = (exportData: any[]): any[] => {
  const data: any[] = []
  if (exportData && exportData.length) {
    exportData.forEach(category => {
      category.values.forEach((row: any) => {
        const elems = Object.entries(row.values)
        let item: any = {
          'カテゴリ': category.title,
          'タイプ': row.title,
        }
        const option = row.valueType === 'percentage' ? '%' : ''
        elems.forEach(elem => {
          const colName = strIns(elem[0], 4, '年') + '月'
          item[colName] = `${elem[1]}${option}`
        })
        data.push(item)
      })
    })
    return data
  }
  return []
}

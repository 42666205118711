import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { AppConsts } from '@app/configs/app.configs'
import { environment } from '@environments/environment'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'
import { AccountService } from '@app/services/account.service'

declare const window: any

@Component({
  selector: 'app-isbe-auth',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './isbe-auth.component.html',
  styleUrl: './isbe-auth.component.scss'
})
export class IsbeAuthComponent implements OnInit {
  private core: any
  private token: string | null = ''
  private baseUrl = window.location.origin
  private appLogo = ''
  private isAppCd = 'Micomel'  // TODO: 🚨 要確認
  private loginUrl = environment.isbeLogin
  private otherLogin = environment.otherLogin
  private organizationDistinctionApp = false
  private organizationDistinctionBrowser = false

  /**
   *
   */
  constructor(
    private accountService: AccountService
  ) {
    this.accountService.account$.subscribe(account => {
      if (account === null) {
        this.logout()
      }
    })
  }

  ngOnInit(): void {
    let url = new URL(document.location.href)
    let params = url.searchParams
    let token = params.get('token')
    if (!isNullOrEmpty(token)) {

      this.token = token
    }

    const isbeLoginScriptPath = this.loginUrl + environment.isbeLoginScript
    const isbeLoginScriptElem = document.createElement('script')
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)

    const isbeLoginOrganizationScriptPath = this.loginUrl + environment.isbeLoginOrganizationScript
    const isbeLoginOrganizationScriptElem = document.createElement('script')
    isbeLoginOrganizationScriptElem.setAttribute('src', isbeLoginOrganizationScriptPath)
    document.head.appendChild(isbeLoginOrganizationScriptElem)

    const that = this
    isbeLoginScriptElem.onload = function () {
      that.loginUrl = location.protocol + that.loginUrl
      that.core = window.BeLoginCore
      that.core.initialize(that)
      isbeLoginScriptElem.onload = null
    }
  }

  onEndLogin = (accountInfo: any): void => {
    this.accountService.accountInfo = JSON.parse(accountInfo)
  }

  logout(): void {
    window.BeLoginCore.logout()
    window.location.replace(`${environment.otherLogin}logout`)
  }
}

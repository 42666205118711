export const environment = {
  production: false,
  /** ミコメルトランザクション */
  micomelTransactionUrl: '//micomelapi.xfrimo.out.orange.ebisudebeer.com',
  /** ミコメルバックエンド */
  micomelBackendUrl: '//micomelapi-backend.xfrimo.out.orange.ebisudebeer.com',
  /** ログインURL */
  isbeLogin: '//backend-login-ui-v3.backend.out.nakamenosakura.com/',
  /** ログインのJSファイルの場所 */
  isbeLoginScript: 'wwwroot/js/be-login-core.js',
  /** ログインのJSファイルの場所 */
  isbeLoginOrganizationScript: 'wwwroot/js/be-select-organization-core.js',
  /** Tokenが無くなった場合の遷移先 */
  otherLogin: '//myidea.backend.out.nakamenosakura.com/',
}

<igx-dialog #downloadDialog [closeOnOutsideSelect]="true">
  <igx-dialog-title>
    <div class="dialog-container">
      <span class="dialog-title">{{dialogTitle}}</span>
    </div>
  </igx-dialog-title>
  <div class="main-content">
    <div class="middle-section">
      <select [(ngModel)]="selectedYear" class="fiscal-year-select" name="selectedYear" (ngModelChange)="changeSelectedYear($event)">
        <option *ngFor="let item of yearList" [selected]="selectedYear === item.value" [ngValue]="item.value">{{item.name}}
        </option>
      </select>
      <label>年 </label>
      <select [(ngModel)]="selectedMonth" class="fiscal-year-select" name="selectedMonth" (ngModelChange)="changeSelectedMonth($event)">
        <option *ngFor="let item of monthList" [selected]="selectedMonth === item.value" [ngValue]="item.value">{{item.name}}
        </option>
      </select>
      <label>月</label>
    </div>
    <div class="msg-area">
      <div>※ しまむらWeb-EDIから受信した明細ファイルをCSV形式でダウンロードできます。</div>
      <div>　 明細ファイルはサプライヤーコード単位となっております。</div>
    </div>
  </div>


  <div igxDialogActions class="dialog-action-bar">
    <button class="cancel-btn" igxButton (click)="cancel()">キャンセル</button>
    <button class="confirm-btn" igxButton (click)="confirm()">ダウンロード</button>
  </div>
</igx-dialog>

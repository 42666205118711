
/**
 * 指定された値が null、undefined または空の文字列 ("") であるかどうかを示します
 * @param obj
 * @returns
 */
export const isNullOrEmpty = (obj: any): boolean => {
  return obj === undefined || obj === null || obj === ''
}

/**
 * オブジェクトのクローン作成
 * @param obj
 * @returns
 */
export const cloneObj = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * カンマを除く
 * ※ 除外できない場合、{val}をそのまま戻す
 * @param val
 * @returns
 */
export const excludeCommas = (val: any): any => {
  return typeof (val) === 'string' ? val.replace(/,/g,'') : val
}

/**
 * string判定
 * @param {any} val
 * @returns
 */
export const valIsString = (val: any): boolean => {
  return typeof (val) === 'string'
}

/**
 * レスポンスが正常であるか判定
 */
export const succeeded = (response: any, arrayData: boolean = false, allowNull: boolean = false): boolean => {
  if (response && response.resultCode === 0) {
    if (arrayData) {
      return Array.isArray(response.data)
    }

    if (response.data !== null || allowNull) {
      return true
    }
  }
  return false
}

export const strIns = (str: string, idx: number, val: string): string => {
  return str.slice(0, idx) + val + str.slice(idx)
}

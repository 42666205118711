import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '@environments/environment'
import { AccountService } from './account.service'

@Injectable({ providedIn: 'root' })
export class MicomelApiService {
  private micomelUrl = environment.micomelTransactionUrl
  constructor(private http: HttpClient, private accountSvc: AccountService) {}

  /**
   * 売上計画取得
   * @param applicationId
   * @param targetYear
   * @returns
   */
  getSalesPlan(fromYearMonth: number, payload: string[]): Observable<any> {
    const url = `${this.micomelUrl}/api/SalesPlan?fromYearMonth=${fromYearMonth}`
    return this.http.post<any>(url, payload)
  }

  /**
   * 売上計画更新
   * @param applicationId
   * @param targetYear
   * @param body
   * @returns
   */
  updateSalesPlan(applicationId: string, body: any): Observable<any> {
    const url = `${this.micomelUrl}/api/${applicationId}/SalesPlan`
    return this.http.put<any>(url, body)
  }

  /**
   * 売上見込み取得
   * @param applicationId
   * @param targetYear
   * @returns
   */
  getSelseExpected(fromYearMonth: number, toYearMonth: number, payload: string[]): Observable<any> {
    const url = `${this.micomelUrl}/api/ExpectedSales?fromYearMonth=${fromYearMonth}&toYearMonth=${toYearMonth}`
    return this.http.post<any>(url, payload)
  }

  /**
   * 回収見込み取得
   * @param applicationId
   * @param targetYear
   * @returns
   */
  getCollectionExpected(
    fromYearMonth: number,
    toYearMonth: number,
    payload: string[],
    supplierCd: string | null
  ): Observable<any> {
    const url = `${this.micomelUrl}/api/ExpectedCollection?fromYearMonth=${fromYearMonth}&toYearMonth=${toYearMonth}&supplierCd=${supplierCd}`
    return this.http.post<any>(url, payload)
  }
  /**
   * 相殺見込み取得
   * @param applicationId
   * @param targetYear
   * @returns
   */
  getExpectedOffset(fromYearMonth: number, payload: string[]): Observable<any> {
    const url = `${this.micomelUrl}/api/ExpectedOffset?fromYearMonth=${fromYearMonth}`
    return this.http.post<any>(url, payload)
  }

  /**
   * 相殺見込み更新 >> 「返品」と「持ち株」の登録コントローラを分割
   * @param applicationId
   * @param targetYear
   * @returns
   */
  // updateExpectedOffset(applicationId: string, body: any): Observable<any> {
  //   const url = `${this.micomelUrl}/api/${applicationId}/ExpectedOffset`
  //   return this.http.put<any>(url, body)
  // }

  /**
   * 返品登録
   * @param applicationId 事業AppID
   * @param body
   * @returns
   */
  updateExpectedOffsetReturns(applicationId: string, body: any): Observable<any> {
    const url = `${this.micomelUrl}/api/${applicationId}/expectedOffset/returns`
    return this.http.put<any>(url, body)
  }

  /**
   * 持ち株登録
   * @param applicationId サプライヤーのAppId
   * @param body
   * @returns
   */
  updateExpectedOffsetHeldShares(applicationId: string, body: any): Observable<any> {
    const url = `${this.micomelUrl}/api/${applicationId}/expectedOffset/heldShares`
    return this.http.put<any>(url, body)
  }

  /**
   * 支払い明細D/L
   * @param supplierCode
   * @param year
   * @param month
   * @returns
   */
  downloadPaymentDetailCsv(supplierCode: string, year: number, month: number): Observable<any> {
    const option = {
      headers: new HttpHeaders({ Accept: 'plain/text' }),
      responseType: 'blob' as 'json',
    }
    // /api/File/paymentDetail/download
    const url = `${this.micomelUrl}/api/File/paymentDetail/download?supplierCd=${supplierCode}&year=${year}&month=${month}`
    return this.http.get<any>(url, option)
  }

  /**
   * 検収相違D/L
   * @param supplierCode
   * @param year
   * @param month
   * @returns
   */
  downloadAcceptanceDifferenceCsv(
    supplierCode: string,
    year: number,
    month: number
  ): Observable<any> {
    // /api/File/acceptanceDifference/download
    const url = `${this.micomelUrl}/api/File/acceptanceDifference/download?supplierCd=${supplierCode}&year=${year}&month=${month}`
    return this.http.get<any>(url, { responseType: 'blob' as 'json' })
  }

  /**
   * 会計年度設定取得
   * ※ [アプリ契約情報取得]に含まれているので、今現在使ってない
   * @param applicationId
   * @returns
   */
  getFiscalYear(applicationId: string): Observable<any> {
    const url = `${this.micomelUrl}/api/Setting/${applicationId}/fiscalYear`
    return this.http.get<any>(url)
  }

  /**
   * 会計年度設定更新
   * @param applicationId
   * @returns
   */
  updateFiscalYear(applicationId: string, body: any): Observable<any> {
    const url = `${this.micomelUrl}/api/Setting/${applicationId}/fiscalYear`
    return this.http.put<any>(url, body)
  }

  /**
   * 表示期間設定更新
   * @param applicationId
   * @param body
   * @returns
   */
  updateMonthSpan(applicationId: string, body: any): Observable<any> {
    const url = `${this.micomelUrl}/api/Setting/${applicationId}/monthSpan`
    return this.http.put<any>(url, body)
  }

  /**
   * アクションログ登録
   * @param actionObj { cd: '', name: '' }
   */
  sendActionLog(actionObj: any): void {
    try {
      const account = this.accountSvc.accountInfo
      const companyInfo = account.Organizations.find(
        (x: any) => x.OrganizationTypeCd === 'organization_type-company'
      )
      const payload = {
        organizationCd: companyInfo.OrganizationCd,
        organizationName: companyInfo.OrganizationName,
        applicationId: this.accountSvc.currentAppId,
        accountId: account.AccountId,
        customerCd: account.CustomerCd,
        customerName: `${account.FamilyName} ${account.FirstName}`,
        actionCd: actionObj.cd,
        actionName: actionObj.name,
      }
      const url = `${this.micomelUrl}/api/ActionLog`
      // 結果受け取らず
      this.http.post<any>(url, payload).subscribe((_) => {})
    } catch (error) {
      // 落ちないように、エラー無視
      console.log(error)
    }
  }

  getActionLog(from: string, to: string): Observable<any> {
    const url = `${this.micomelUrl}/api/ActionLog?from=${from}&to=${to}`
    return this.http.get<any>(url)
  }

  downloadActionLogCsv(from: string, to: string): Observable<any> {
    const option = {
      headers: new HttpHeaders({ Accept: 'plain/text' }),
      responseType: 'blob' as 'json',
    }
    const url = `${this.micomelUrl}/api/ActionLog/download/csv?from=${from}&to=${to}`
    return this.http.get<any>(url, option)
  }

  // /Calendar/55e28ab9-d32f-4a15-9c2d-a9522d45c2d5/singleFiscalMonthInterval?yearMonth=202303
  /**
   * カレンダー
   * @param applicationId サプライヤーAppID（どちらでも良いとことですが）
   * @param yearMonth
   * @returns
   */
  getSingleFiscalMonthInterval(applicationId: string, yearMonth: string): Observable<any> {
    const url = `${this.micomelUrl}/api/Calendar/${applicationId}/singleFiscalMonthInterval?yearMonth=${yearMonth}`
    return this.http.get<any>(url)
  }

  // SalesDetail
  // /api/monthlyResult?dataType=contract&periodFrom=2023-03-01&periodTo=2023-03-31
  /**
   * 売上見込み詳細取得
   * @param periodFrom 2023-03-01
   * @param periodTo 2023-03-31
   * @param dataType contract/order/shipment
   * @param payload AppId リスト (サプライヤーの場合、全事業分AppIdを渡す)
   * @returns
   */
  getSelseExpectedDetail(
    periodFrom: string,
    periodTo: string,
    dataType: string,
    payload: string[]
  ): Observable<any> {
    const url = `${this.micomelUrl}/api/monthlyResult?dataType=${dataType}&periodFrom=${periodFrom}&periodTo=${periodTo}`
    return this.http.post<any>(url, payload)
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IgxIconModule } from '@infragistics/igniteui-angular'
import { AccountService } from '@app/services/account.service'

@Component({
  selector: 'app-bottom-bar',
  standalone: true,
  imports: [CommonModule, IgxIconModule],
  templateUrl: './bottom-bar.component.html',
  styleUrl: './bottom-bar.component.scss'
})
export class BottomBarComponent {
  @Output() submit = new EventEmitter<any>()
  @Output() clickBackButton = new EventEmitter<any>()
  @Input() showBackButton: boolean = false

  downloadCsv(): void {
    this.submit.emit()
  }

  /**
   * 当面「売上見込み」専用
   */
  backBack(): void {
    this.clickBackButton.emit()
  }
}

import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'

@Component({
  selector: 'app-expected-grid',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './expected-grid.component.html',
  styleUrl: './expected-grid.component.scss'
})
export class ExpectedGridComponent {
  @Output() showDetailView = new EventEmitter<any>()
  @Input() headerData: any[] = []
  @Input() bodyData: any[] = []
  @Input() amountUnit: number = 1000
  @Input() isExpectedSalesView: boolean = false

  constructor(private router: Router,) {

  }

  valType = {
    date: 'date',
    string: 'string',
    amount: 'amount',
    percentage: 'percentage',
  }

  showOriginalValue(row: any, elem: any, index: number): string {
    let ret = ''
    const val = elem[1] // "202301": 1000 ← これ
    // 金額以外無視
    if (row.valueType !== this.valType.amount) return ret
    // // 空白又は0無視
    // if (isNullOrEmpty(val) || val === 0) return ret
    // 既に単位が円の場合無視
    if (this.amountUnit === 1) return ret

    const fieldName = elem[0] // これ → "202301": 1000
    const originalValueObj = row.originalValues
    if (!isNullOrEmpty(fieldName) && !isNullOrEmpty(originalValueObj)) {
      const originalVal = originalValueObj[fieldName]
      if (!isNullOrEmpty(originalVal) && originalVal !== 0) {
        ret = `${originalVal.toLocaleString()}円`
      }
    }
    return ret
  }

  getFieldItems(values: any): any[] {
    return Object.entries(values)
  }

  isLinkElem(category: any, row: any, elem: any): boolean {
    // console.log(`category`, category)
    // console.log(`row`, row)
    // console.log(`elem`, elem)

    if (!this.isExpectedSalesView) {
      return false
    }

    // 先付実績(contract)&&先付実績(contractPerformance)
    if (category.dataType === 'contract' && row.dataType === 'contractPerformance') {
      return true
    }

    // A伝実績(order)&&A伝実績(orderPerformance)
    if (category.dataType === 'order' && row.dataType === 'orderPerformance') {
      return true
    }

    // 出荷実績(shipment)&&出荷実績(shipmentPerformance)
    if (category.dataType === 'shipment' && row.dataType === 'shipmentPerformance') {
      return true
    }

    return false
  }

  navigate(category: any, row: any, elem: any): void {
    if (this.isLinkElem(category, row, elem)) {
      const payload: any = {}
      // 先付実績(contract)&&先付実績(contractPerformance)
      if (category.dataType === 'contract' && row.dataType === 'contractPerformance') {
        payload.viewType = 'contract'
      }

      // A伝実績(order)&&A伝実績(orderPerformance)
      if (category.dataType === 'order' && row.dataType === 'orderPerformance') {
        payload.viewType = 'order'
      }

      // 出荷実績(shipment)&&出荷実績(shipmentPerformance)
      if (category.dataType === 'shipment' && row.dataType === 'shipmentPerformance') {
        payload.viewType = 'shipment'
      }
      payload.targetMonth = elem[0]
      this.showDetailView.emit(payload)
    }
  }

  /** CSS Class 関連 */
  rowspanCell = ['rowspan-obj', 'last-td-border']

  setColSpan(i: number): number {
    return i ? 1 : 2
  }

  widthVal(): string {
    if (this.amountUnit === 1) {
      return 'col-3'
    } else if (this.amountUnit === 1000) {
      return 'col-6'
    } else if (this.amountUnit === 1000000) {
      return 'col-12'
    }
    return 'col-6'

    // if (this.headerData.length === 13) {
    //   // 12ヶ月
    //   return 'col-12'
    // } else if (this.headerData.length === 7) {
    //   // 6ヶ月
    //   return 'col-6'
    // } else {
    //   // 3ヶ月とそのた
    //   return 'col-3'
    // }
  }

  stickyRow(categoryIndex: number, rowIndex: number): string {
    // sticky-row
    if (categoryIndex === 0) {
      return `sticky-row-${ rowIndex + 1 }`
    }
    return ''
  }


  setCellClass(categoryObj: any, rowObj: any, categoryIndex: number, index: number, elemObj: any = undefined): string {
    let ret = ''
    const objDataType = categoryObj.dataType
    const rows = categoryObj.values
    if (index === 0) {
      switch (objDataType) {
        case 'plan':
          ret = 'plan-bg-color-style'
          break
        case 'contract':
          ret = 'contract-bg-color-style'
          break
        case 'order':
          ret = 'order-bg-color-style'
          break
        case 'shipment':
          ret = 'shipment-bg-color-style'
          break
        case 'performanceAndForecast':
          // がっつり固定(^_^;)
          if (rowObj.dataType === 'paymentPerformance') {
            // 入金実績
            ret = 'paymentPerformance-bg-color-style'
          }
          break
        default:
          break
      }
    }

    // 回収対応
    if (index === 1) {
      if (categoryObj.dataType === 'performanceAndForecast') {
        if (rowObj.dataType === 'paymentForecas') {
          // 入金見込
          ret = 'paymentForecas-bg-color-style'
        }
      }
    }

    if (elemObj === undefined) {
      ret = `${ret} td-obj standard-td`
    }

    if (!isNullOrEmpty(elemObj) && !isNaN(Number(elemObj[1])) && elemObj[1] < 0) {
      ret = `${ret} red-string`
    }

    if (rows.length - 1 === index) {
      ret = `${ret} last-td-border`
    }

    ret = `${ret} ${this.stickyRow(categoryIndex, index)}`

    return ret
  }
}

<igx-nav-drawer id="navigation" #drawer [isOpen]="opened" [pin]="true" [pinThreshold]="0" width="260px">
  <ng-template igxDrawer class="test">
    <nav class="nav-block">
      <ng-container *ngFor="let item of navItems">
        <span igxDrawerItem [active]="item.name === selected" igxRipple (click)="navigate(item)">
          <img class="menu-icon" [src]="item.icon" width="24" height="24" alt="" />
          <span class="menu-title">{{ item.text }}</span>
          <ng-container *ngIf="item.expansionPanel">
            <igx-icon (click)="expandPanel(item, $event)" family="material">{{controlArrow(item)}}</igx-icon>
          </ng-container>
        </span>
        <ng-container *ngIf="item.downloadOnly">
          <span *ngIf="openDownloadPanel" igxDrawerItem igxRipple (click)="openDownloadDialog(fileType.acceptanceDifference)" class="sub-menu">
            <span class="sub-menu-title">検収相違</span>
          </span>
          <span *ngIf="openDownloadPanel" igxDrawerItem igxRipple (click)="openDownloadDialog(fileType.paymentDetail)" class="sub-menu">
            <span class="sub-menu-title">支払明細</span>
          </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="expansionPanelOpend">
        <span *ngFor="let item of navSubItems" igxDrawerItem
          [active]="item.name === selected" igxRipple (click)="navigate(item)" class="sub-menu">
          <span class="sub-menu-title">{{ item.text }}</span>
        </span>
      </ng-container>
    </nav>
    <app-privacy-policy></app-privacy-policy>
  </ng-template>
</igx-nav-drawer>

<app-download-dialog
  [dialogTitle]="dialogTargetTitle"
  [downloadFileType]="downloadFileType"
></app-download-dialog>

<app-confirmation-dialog
  #warningDialog
  [clearBtn]="true"
  okBtnTitle="変更する"
  clearBtnTitle="キャンセル"
  title="メッセージ"
  (submit)="yesJump()">
  <div>この設定を変更すると過去データも全て再集計が必要となります。</div>
  <div>再集計処理には時間が掛かりますが変更しますか？</div>
</app-confirmation-dialog>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { EditableGridComponent } from '@app/views/_components/editable-grid/editable-grid.component'
import { HorizontalAlignment, IgxSnackbarComponent, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular'
import { AccountService } from '@app/services/account.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { FiscalYearCalendarService } from '@app/services/fiscal-year-calendar.service'
import { AppConsts } from '@app/configs/app.configs'
import { cloneObj, excludeCommas, isNullOrEmpty, succeeded } from '@app/helpers/utils.toolkit'
import { NotificationService } from '@app/services/notification.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-expected-offset',
  standalone: true,
  imports: [CommonModule, FormsModule, EditableGridComponent],
  templateUrl: './expected-offset.component.html',
  styleUrl: './expected-offset.component.scss',
  host: { class:'content-container-wrap'}
})
export class ExpectedOffsetComponent implements OnInit, OnDestroy {
  loading = false
  submitting = false
  startMonth: number
  gridData: any[] = []
  headerData: any[] = []
  rowInfo: any[] = []
  currentUnit: number
  /** 金額の単位指定 */
  amountUnitItems: readonly any[]
  targetYear = new Date().getFullYear()
  yearList: any[] = []
  msg = '登録中です'

  // private applicationId: string | null = null // 事業IDに変更
  private currentAppId: string | null = null
  private viewKey = 'expected-offset'

  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private accountSvc: AccountService,
    private micomelApiSvc: MicomelApiService,
    private notificationSvc: NotificationService,
    private fiscalYearCalendar: FiscalYearCalendarService,
  ) {
    // this.applicationId = this.accountService.applicationId
    this.currentAppId = this.accountSvc.currentAppId
    this.yearList = this.fiscalYearCalendar.getYearList(true)
    this.targetYear = this.fiscalYearCalendar.getSelectedYear(this.viewKey)
    this.currentUnit = this.accountSvc.amountUnit
    this.amountUnitItems = AppConsts.amountUnitItems
    this.startMonth = this.accountSvc.fiscalYearSetting.closingMonth
  }

  ngOnInit(): void {
    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe(appId => {
      this.clearGrid()
      this.currentAppId = appId
    })
  }

  showExpectedOffset(): void {
    this.loading = true
    this.gridData = []
    this.fiscalYearCalendar.setYearMonthVal(this.viewKey, this.targetYear)
    const fromYearMonth = this.getYearMonth(this.targetYear, this.startMonth)
    const appIds = this.accountSvc.getCurrentAppIds
    if (this.accountSvc.currentSelectedIsSupplier) {
      // ※親のAppIdと子のAppIdを同じ階層の配列に突っ込む
      appIds.push(this.accountSvc.currentSupplierObj.applicationId)
    }
    this.micomelApiSvc.getExpectedOffset(fromYearMonth, appIds)
      .subscribe({
        next: (response: any) => {
          if (succeeded(response)) {
            this.gridData = cloneObj(response.data)
            this.headerData = this.createHeaderData(this.gridData)
            this.convertUnitNoTruncation(this.gridData, this.currentUnit, false)
            this.rowInfo = this.createRowInfo()
          } else {
            // 異常
            this.notificationSvc.showNotification(response?.resultMessage, false)
          }
        },
        error: err => {
          const msg = '相殺見込みデータ取得が失敗しました。'
          this.notificationSvc.showNotification(msg, false)
        }
      }).add(() => {
        this.loading = false
      })
  }

  private createRowInfo(): any[] {
    const refundAmountRow = 'returns' // 返品
    const holdingAmountRow = 'heldShares' // 持ち株
    const rows = []
    const currentIsSupplier = this.accountSvc.currentSelectedIsSupplier

    const returnsRowName = currentIsSupplier ? '返品(事業合算）' : '返品'

    rows.push({ value: refundAmountRow, name: returnsRowName, total: '', disabled: currentIsSupplier })
    this.calculateTotal(rows[0])

    if (currentIsSupplier) {
      rows.push({ value: holdingAmountRow, name: '持ち株', total: '' })
      this.calculateTotal(rows[1])
    }
    return rows
  }

  private calculateTotal(rowInfo: any): void {
    let total = 0
    this.gridData.forEach(obj => {
      if (obj.dataType === rowInfo.value) {
        if (!isNullOrEmpty(obj.value) && !isNaN(Number(obj.value))) {
          total += Number(obj.value)
        }
      }
    })
    rowInfo.total = total > 0 ? total : null
  }

  private createHeaderData(gridData: any[]): any[] {
    const refundAmount = 'returns' // 返品
    return gridData.filter((x: any) => x.dataType === refundAmount)
  }

  /**
   * 相殺見込み登録
   * 変わるかも、一旦「返品」と「持ち株」を同じところにしてある
   * 確定したら独立させる
   */
  updateExpectedOffset(): void {
    if (this.gridData === null || this.gridData.length === 0) {
      this.msg = '表示してから登録を行ってください。'
      this.notificationSvc.showNotification(this.msg, false)
      return
    }
    if (this.submitting) {
      return
    }

    if (!this.validateSaveData(this.gridData)) {
      this.msg = '入力内容を確認して訂正した上登録を行ってください。'
      this.notificationSvc.showNotification(this.msg, false)
      return
    }

    this.calibrateDataType(this.gridData)

    this.submitting = true
    this.msg = '相殺見込み設定を登録中です'
    this.notificationSvc.showNotification(this.msg, this.submitting)

    const originalData = cloneObj(this.gridData)
    if (this.accountSvc.currentSelectedIsSupplier) {
      // サプライヤー選択
      const filterKey = 'heldShares' // 持ち株
      const payload = originalData.filter((o: any) => o.dataType === filterKey)
      this.convertUnitNoTruncation(payload, this.currentUnit, true)
      const appId = this.accountSvc.currentSupplierObj.applicationId
      this.micomelApiSvc.updateExpectedOffsetHeldShares(appId, payload).subscribe({
        next: (response) => {
          if (succeeded(response, false, true)) {
            this.msg = '相殺見込み設定を登録しました'
            this.notificationSvc.showNotification(this.msg, false)
          } else {
            // 異常
            this.msg = response?.resultMessage
            this.notificationSvc.showNotification(response?.resultMessage, false)
          }
        },
        error: (err: any) => {
          console.log(`registerSalesPlan() =>> Error`, err)
          this.msg = '登録失敗しました。'
          this.notificationSvc.showNotification(this.msg, false)
        },
        complete: () => { this.submitting = false }
      }).add(() => {
        this.submitting = false
      })
    } else {
      // 事業選択
      const filterKey = 'returns' // 返品
      const payload = originalData.filter((o: any) => o.dataType === filterKey)
      this.convertUnitNoTruncation(payload, this.currentUnit, true)
      this.micomelApiSvc.updateExpectedOffsetReturns(this.currentAppId!, payload).subscribe({
        next: (response) => {
          if (succeeded(response, false, true)) {
            this.msg = '相殺見込み設定を登録しました'
            this.notificationSvc.showNotification(this.msg, false)
          } else {
            // 異常
            this.msg = response?.resultMessage
            this.notificationSvc.showNotification(response?.resultMessage, false)
          }
        },
        error: (err: any) => {
          console.log(`registerSalesPlan() =>> Error`, err)
          this.msg = '登録失敗しました。'
          this.notificationSvc.showNotification(this.msg, false)
        },
        complete: () => { this.submitting = false }
      }).add(() => {
        this.submitting = false
      })
    }
  }

  /**
   * 相殺見込み登録 >> 「返品」と「持ち株」の登録コントローラを分割
   * V2対応で廃棄 02/07
   * @returns
   */
  // registerSalesPlan() {

  //   if (this.gridData === null || this.gridData.length === 0) {
  //     this.msg = '表示してから登録を行ってください。'
  //     this.notificationSvc.showNotification(this.msg, false)
  //     return
  //   }
  //   if (this.submitting) {
  //     return
  //   }

  //   if (!this.validateSaveData(this.gridData)) {
  //     this.msg = '入力内容を確認して訂正した上登録を行ってください。'
  //     this.notificationSvc.showNotification(this.msg, false)
  //     return
  //   }

  //   this.calibrateDataType(this.gridData)

  //   this.submitting = true
  //   this.msg = '相殺見込み設定を登録中です'
  //   this.notificationSvc.showNotification(this.msg, this.submitting)

  //   const payload = cloneObj(this.gridData)
  //   this.convertUnitNoTruncation(payload, this.currentUnit, true)

  //   this.micomelApiSvc.updateExpectedOffset(this.currentAppId!, payload).subscribe({
  //     next: (response: any) => {
  //       if (succeeded(response, false, true)) {
  //         this.msg = '相殺見込み設定を登録しました'
  //         this.notificationSvc.showNotification(this.msg, false)
  //       } else {
  //         // 異常
  //         this.msg = response?.resultMessage
  //         this.notificationSvc.showNotification(response?.resultMessage, false)
  //       }
  //     },
  //     error: (err: any) => {
  //       console.log(`registerSalesPlan() =>> Error`, err)
  //       this.msg = '登録失敗しました。'
  //       this.notificationSvc.showNotification(this.msg, false)
  //     },
  //     complete: () => { this.submitting = false }
  //   }).add(() => {
  //     this.submitting = false
  //   })
  // }

  changeSelectedYear(year: any): void {
    this.targetYear = year
  }

  changeAmountUnit(val: any): void {
    this.convertUnitNoTruncation(this.gridData, this.currentUnit, true)
    this.convertUnitNoTruncation(this.gridData, val, false)
    this.currentUnit = val
    this.calculateTotal(this.rowInfo[0])
    if (this.rowInfo.length === 2) {
      this.calculateTotal(this.rowInfo[1])
    }
  }

  /**
   * Inputのフォーカスを失う際　有効性チェック、計算
   * @param event
   * @param item
   */
  onFocusOut(event: any, item: any) {
    const target = event.target
    const targetVal = excludeCommas(target.value)
    if (!this.isValidValue(targetVal, true)) {
      console.log(`入力した値が無効：${item.value}`)
      return
    }
    item.value = targetVal
    // this.planTotal = null
    const gridData = this.gridData
    let totalVal: number = 0
    gridData.forEach((obj: any, index: number) => {
      if (index <= 11) {
        const salesPlanObj = gridData[index]
        const salesPlanVal = this.isValidValue(salesPlanObj.value) ? Number(salesPlanObj.value) : 0
        totalVal += salesPlanVal
      }
    })

    // this.planTotal = totalVal
  }

  /**
   * 保存データの整合性チェック
   */
  private validateSaveData(data: any): boolean {
    if (isNullOrEmpty(data) || !Array.isArray(data) || data.length === 0) {
      return false
    }
    let ret = true
    for (let i = 0; i < data.length; i++) {
      const obj = data[i]
      let val = obj.value
      if (val === undefined || val === null || val === '') {
        // OKとする
      } else {
        if (isNaN(Number(val))) {
          ret = false
          break
        }
      }
    }
    return ret
  }

  /**
   * 校正 データ型を調整する Valueの値をNumber | null　に変換
   *
   * ★ validateSaveData()を通した前提 ★
   * @param data
   */
  private calibrateDataType(data: any): void {
    console.log(`校正前：`, JSON.parse(JSON.stringify(data)))
    data.forEach((obj: any) => {
      let val = obj.value
      // Null OR "" OK
      if (val === undefined || val === '') {
        val = null // C# 対応
        obj.value = val
      } else if (val === null || val === 0) {
        // OKとする
      } else {
        if (isNaN(Number(val))) {
          throw new Error('Value is not a number!')
        } else {
          obj.value = Number(val)
        }
      }
    })
    console.log(`校正後：`, JSON.parse(JSON.stringify(data)))
  }
  /**
   * 入力有効性検証
   * 数字であるかをチェック
   * @param val
   */
  isValidValue(val: any, allowEmpty: boolean = false): boolean {
    if (allowEmpty && isNullOrEmpty(val)) {
      return true
    }
    return isNullOrEmpty(val) || isNaN(Number(val)) ? false : true
  }

  onEnter($event: any, i: any): void {
    let index = i
    if ($event.key !== 'Enter') return
    if ($event.shiftKey) {
      index = i - 1
    } else {
      index = i + 1
    }
    const id = this.gridData[index]?.yearMonth
    document.getElementById(id)?.focus()
  }

  // NoTruncation
  // 単位変換
  private convertUnitNoTruncation(convertData: any[], currentUnit: number, fullAmount: boolean): void {
    if (!Array.isArray(convertData)) return

    convertData.forEach((obj: any) => {
      let val = obj.value
      // 変換不要やできないものは除外
      if (!isNullOrEmpty(val) && val !== 0 && !isNaN(Number(val))) {
        if (fullAmount) {
          // 保存など、元の数字(通常単位：1円)に戻す
          obj.value = Number(val) * currentUnit
        } else {
          // 表示/編集など、指定単位に変換
          obj.value = Number(val) / currentUnit
        }
      }
    })
  }


  private clearGrid(): void {
    this.gridData = []
  }

  public newPositionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Top,
    verticalStartPoint: VerticalAlignment.Middle
  }

  /**
   * 締め月プラス1ヶ月、13になった場合-1とする
   * @param yearVal
   * @param monthVal
   */
  private getYearMonth(yearVal: number, monthVal: number): number {
    const closingMonth = monthVal === 12 ? 1 : monthVal + 1
    return Number(yearVal + closingMonth.toString().padStart(2, '0'))
  }

  ngOnDestroy(): void {
    this.currentSubApp$.unsubscribe()
  }
}

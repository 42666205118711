import { Component, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HorizontalAlignment, IgxSnackbarComponent, IgxSnackbarModule, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular'
import { NotificationService } from '@app/services/notification.service'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'

@Component({
  selector: 'app-global-snackbar',
  standalone: true,
  imports: [CommonModule, IgxSnackbarModule],
  templateUrl: './global-snackbar.component.html',
  styleUrl: './global-snackbar.component.scss'
})
export class GlobalSnackbarComponent {
  @ViewChild('snackbar', { static: true }) public snackbar!: IgxSnackbarComponent

  loading: boolean = false
  msg: string = ''

  constructor(
    private notificationSvc: NotificationService
  ) {
    this.notificationSvc.notification$.subscribe(obj => {
      this.openSnackbar(obj)
    })
  }

  openSnackbar(obj: any): void {
    if (!isNullOrEmpty(obj)) {
      this.loading = obj.loading
      this.msg = obj.msg
      this.snackbar.open()
    }
  }

  newPositionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Top,
    verticalStartPoint: VerticalAlignment.Middle
  }
}

<div class="content-container">
  <h6 class="view-title">日次推移：{{subTitle}}</h6>

  <div class="lower-section">
    <span>
      <span class="update-date-title">最終更新日時：</span><span class="update-datetime">{{updatedDate | date:"yyyy年MM月dd日 HH時mm分ss秒"}}</span>
    </span>
    <span>
      <select [ngModel]="currentUnit" name="selectedYear" class="normal-select" (ngModelChange)="changeAmountUnit($event)">
        <option *ngFor="let item of amountUnitItems" [selected]="item.value === currentUnit" [ngValue]="item.value">{{item.name}}</option>
      </select>
    </span>
  </div>

  <div class="grid-container" *ngIf="gridData && gridData.length; else elseBlock">
    <table class="grid-base-style">
      <thead>
        <th class="col-width-135" [attr.colspan]="2">{{targetYear}}</th>
        <th [class]="widthVal()">実績</th>
        <th [class]="widthVal()">前日差額</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of gridData"
          [class.sunday]="item.dayOfWeek === '日'"
          [class.saturday]="item.dayOfWeek === '土'"
        >
          <td class="text-align-center col-width-100">{{item.date | date:"MM月dd日"}}</td>
          <td class="text-align-center col-width-35">{{item.dayOfWeek}}</td>
          <td class="text-align-right">{{item.cumulativeValue | number : '1.0-0'}}</td>
          <td class="text-align-right">{{item.value | number : '1.0-0'}}</td>
          <!-- <td>{{item.date}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #elseBlock>
    <div class="skeleton-msg">Loading...</div>
  </ng-template>

</div>
<div class="bottom-bar-area">
  <app-bottom-bar (submit)="exportCsv()" [showBackButton]="true" (clickBackButton)="clickBackButton($event)"></app-bottom-bar>
</div>

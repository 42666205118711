<div class="sidenav__other-link">
  <a href="https://webedi.shimamura-api.com/Base/Front/Auth/Login" target="_blank" rel="noopener" class="btn btn--subtle shimamura-web-edi">しまむらWEB-EDI</a>
</div>

<ul class="side-munu-sub-content sidenav__list sidenav__list--second">
  <li class="sidenav__item">
    <a
      href="https://www.interserve.co.jp/"
      target="_blank"
      rel="noopener"
      class="sidenav__link"
    >
      <span class="sidenav__text text-sm@lg">運営会社</span>
    </a>
  </li>

  <li class="sidenav__item">
    <a
      href="http://idea-place.jp/sp/common/riyoukiyaku.pdf"
      target="_blank"
      rel="noopener"
      class="sidenav__link"
    >
      <span class="sidenav__text text-sm@lg">利用規約</span>
    </a>
  </li>

  <li class="sidenav__item">
    <a
      href="https://www.interserve.co.jp/privacy/"
      target="_blank"
      rel="noopener"
      class="sidenav__link"
    >
      <span class="sidenav__text text-sm@lg">プライバシーポリシー</span>
    </a>
  </li>
</ul>
<p class=" side-munu-sub-content sidenav__copyright">
  <small class="text-xs">©︎ INTERSERVE Corporation.</small>
</p>

<table class="grid-base-style">
  <thead>
    <ng-container *ngFor="let item of headerData; let i = index">
      <th
        [class.sticky-col]="i === 0"
        [class]="widthVal()"
        class="sticky-row"
        [attr.colspan]="setColSpan(i)"
        [title]="item.tooltip">{{item.title}}</th>
    </ng-container>
  </thead>
  <tbody>
    <ng-container *ngFor="let category of bodyData; let j = index">
      <tr *ngFor="let row of category.values; let i = index">
        <td *ngIf="i === 0" [class]="rowspanCell"
          [attr.rowspan]="category.values.length"
          [class.sticky-row-1]="j === 0"
          class="sticky-col">{{category.title}}</td>

        <td [class]="setCellClass(category, row, j, i)"
          class="sticky-col-2" >{{row.title}}</td>

        <ng-container *ngFor="let elem of getFieldItems(row.values); let x = index">
          <td (click)="navigate(category, row, elem)" [class.can-click]="isLinkElem(category, row, elem)" [class]="setCellClass(category, row, j, i, elem)" [title]="showOriginalValue(row, elem, x)">
            <ng-container *ngIf="isLinkElem(category, row, elem); else elseBlock">
              <ng-container *ngIf="row.valueType === valType.amount">
                <span class="link-btn">{{elem[1] | number : '1.0-0'}}</span>
              </ng-container>
            </ng-container>
            <ng-template #elseBlock>
              <ng-container *ngIf="row.valueType === valType.amount">{{elem[1] | number : '1.0-0'}}</ng-container>
              <ng-container *ngIf="row.valueType === valType.percentage">{{elem[1] + '%'}}</ng-container>
              <ng-container
                *ngIf="row.valueType !== valType.percentage && row.valueType !== valType.amount">{{elem[1]}}</ng-container>
            </ng-template>
          </td>
        </ng-container>

      </tr>
    </ng-container>
  </tbody>
</table>

import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NavigationStart, Route, Router } from '@angular/router'
import { IgxDialogComponent, IgxIconModule, IgxNavbarModule, IgxNavigationDrawerModule } from '@infragistics/igniteui-angular'

import { SideMenuService } from '@app/services/side-menu.service'
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component'
import { DownloadDialogComponent } from '@app/views/_components/download-dialog/download-dialog.component'
import { AccountService } from '@app/services/account.service'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'
import { ConfirmationDialogComponent } from '@app/views/_components/confirmation-dialog/confirmation-dialog.component'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, IgxNavigationDrawerModule, IgxIconModule, IgxNavbarModule, PrivacyPolicyComponent, DownloadDialogComponent,
    ConfirmationDialogComponent],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @ViewChild(DownloadDialogComponent)
  private downloadDialogComponent!: DownloadDialogComponent

  @ViewChild('warningDialog', { static: true })
  public warningDialog!: ConfirmationDialogComponent

  /** サイドメニューOn/Offスイッチ */
  opened = true
  /** サブメニュー展開スイッチ */
  expansionPanelOpend = false
  openDownloadPanel = false

  routeSubscription: any = null
  selected = '/expected/sales'

  dialogTargetTitle =''
  navItems = [
    { name: '/expected/sales', text: '売上見込み', icon: 'assets/images/graph.svg' },
    { name: '/expected/collection', text: '回収見込み', icon: 'assets/images/graph.svg' },
    { name: '/expected/salesPlan', text: '売上計画入力 (事業別)', icon: 'assets/images/edit.svg' },
    { name: '/downloadDetails', text: '明細確認', icon: 'assets/images/juchu.svg', expansionPanel: true, downloadOnly: true },
    { name: '/settings', text: '設定', icon: 'assets/images/setting.svg', expansionPanel: true },
  ]

  navSubItems = [
    { name: '/settings/expectedOffset', text: '相殺見込み設定', icon: '' },
    { name: '/settings/monthSpan', text: '表示設定', icon: '' },
    { name: '/settings/fiscalYear', text: '年度締め設定', icon: '' },
  ]
  fiscalYearSettingView = '/settings/fiscalYear'

  fileType = {
    paymentDetail: 'paymentDetail', // 支払明細
    acceptanceDifference: 'acceptanceDifference' // 検収相違
  }
  downloadFileType = ''

  private sideMenuSwitch$: Subscription
  private currentMenu$: Subscription
  /**
   *
   */
  constructor(
    private router: Router,
    private accountSvc: AccountService,
    private sideMenuService: SideMenuService,
  ) {
    this.sideMenuSwitch$ = this.sideMenuService.sideMenuSwitch$.subscribe(x => this.operateSideMenu(x))
    this.currentMenu$ = this.sideMenuService.currentMenu$.subscribe(x => this.changedMenu(x))
  }

  ngOnInit(): void {
    this.selected = this.router.url
    this.changedNavigation(this.router)
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.changedNavigation(event)
        // TODO: 必要なくなった、次の改修で要件変わらなかったら消す
        // this.checkInitialSetting()
      }
    })
  }

  // TODO: 必要なくなった、次の改修で要件変わらなかったら消す
  // private checkInitialSetting(): void {
  //   const setting = this.accountSvc?.fiscalYearSetting
  //   if (this.noSetting(setting)) {
  //     this.router.navigate([this.fiscalYearSettingView])
  //     this.navigate({ name: '/settings/fiscalYear', text: '年度締め設定', icon: '' })
  //   }
  // }

  // TODO: 必要なくなった、次の改修で要件変わらなかったら消す
  // private noSetting(fiscalYearSetting: any): boolean {
  //   if (isNullOrEmpty(fiscalYearSetting) ||
  //     isNullOrEmpty(fiscalYearSetting.closingMonth) ||
  //     isNullOrEmpty(fiscalYearSetting.closingDate)) {
  //     return true
  //   }
  //   return false
  // }

  private isFiscalYearSettingView(viewName: string): boolean {
    if (viewName === this.fiscalYearSettingView && !this.accountSvc.yesShowMe) {
      this.warningDialog.openDialog()
      return true
    }
    return false
  }

  yesJump(): void {
    this.accountSvc.yesShowMe = true
    this.navigate({ name: '/settings/fiscalYear', text: '年度締め設定', icon: '' })
  }

  navigate(item: any) {
    if (this.isFiscalYearSettingView(item.name)) {
      return
    }
    if (item.expansionPanel) {
      if (item.downloadOnly) {
        this.openDownloadPanel = !this.openDownloadPanel
      } else {
        this.expansionPanelOpend = !this.expansionPanelOpend
      }
    } else {
      this.selected = item.name
      this.router.navigateByUrl(item.name)
    }

    // 売上見込み画面のキャッシュをクリアしておく
    this.clearExpectedSalesViewOriginalData()
  }

  expandPanel(item: any, event: any): void {
    if (item.downloadOnly) {
      this.openDownloadPanel = !this.openDownloadPanel
    } else {
      this.expansionPanelOpend = !this.expansionPanelOpend
    }

    event.stopPropagation()
  }

  openDownloadDialog(fileType: any): void {
    if (this.fileType.paymentDetail === fileType) {
      this.dialogTargetTitle = '支払明細'
    } else {
      this.dialogTargetTitle = '検収相違'
    }
    this.downloadFileType = fileType
    this.downloadDialogComponent.openDialog()
  }

  controlArrow(item: any): string {
    if (item.downloadOnly) {
      return this.openDownloadPanel ? 'expand_less' : 'expand_more'
    } else {
      return this.expansionPanelOpend ? 'expand_less' : 'expand_more'
    }
  }

  private operateSideMenu(openIt: boolean): void {
    this.opened = openIt
  }

  private changedMenu(currentMenu: string): void {
    this.selected = currentMenu
  }

  private changedNavigation(event: any): void {
    this.selected = event.url
    // とりあえず、雑に...
    if (event.url.startsWith('/settings/')) {
      this.expansionPanelOpend = true
    }
  }

  closingDialog(event: any): void {

  }


  openingDialog(): void {
    // this.apps = cloneObj(this.accountService.applications)
    // if (this.accountService.applicationId) {
    //   const obj = this.apps.find(x => x.applicationId === this.accountService.applicationId)
    //   if (obj) {
    //     obj.expand = true
    //     this.selectedNode = obj.children
    //   }
    // }

  }

  clearExpectedSalesViewOriginalData(): void {
    this.accountSvc.expectedSalesViewOriginalData = null
  }

  ngOnDestroy(): void {
    this.sideMenuSwitch$.unsubscribe()
    this.currentMenu$.unsubscribe()
  }
}

import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { AppConsts } from '@app/configs/app.configs'
import { isNullOrEmpty } from '@app/helpers/utils.toolkit'

@Injectable({ providedIn: 'root' })
export class AccountService {
  account$: Observable<any>
  private accountSubject = new Subject<any>()

  currentApp$: Observable<any>
  private currentAppSubject = new Subject<any>()

  private _accountInfo: any | null = null
  private _applications: any[] | null = null
  private _currentSupplierObj: any | null = null

  private _currentAppId: string = ''
  private _currentAppObj: any
  private _amountUnit: number

  constructor(
    private router: Router,
  ) {
    this.account$ = this.accountSubject.asObservable()
    this.currentApp$ = this.currentAppSubject.asObservable()
    this._amountUnit = this.initAmountUnit()
  }

  /**
   * アプリケーション情報
   */
  set applications(v: any[] | null) {
    this._applications = v
    this.init(v)
  }

  /**
   * 初期処理
   * @param apps
   */
  private init(apps: any[] | null): void {
    if (apps) {
      const lastSelectedAppId = localStorage.getItem(AppConsts.lastSelectedAppId)
      const lastSelectedSupplierCd = localStorage.getItem(AppConsts.lastSelectedSupplierCd)
      if (lastSelectedAppId && this.validateAppId(lastSelectedAppId)) {
        // 前回の選択が有効の場合
        this._supplierCode = lastSelectedSupplierCd?lastSelectedSupplierCd:''
        this.resetSelectedObj(lastSelectedAppId)
      } else {
        // 初めての利用または別のアカウントで利用した場合
        if (apps.length === 1) {
          // サプライヤコード一つしかない場合、自動選択
          this._currentSupplierObj = apps[0]
          this._currentSupplierObj.selected = true
          if (this._currentSupplierObj &&
              this._currentSupplierObj.children &&
              Array.isArray(this._currentSupplierObj.children) &&
              this._currentSupplierObj.children.length === 1) {

            // 事業一つしかない場合、自動選択
            this._currentSupplierObj.children[0].selected = true
            this.currentAppId = this._currentSupplierObj.children[0].applicationId
          }
        }
      }
    }
  }

  /**
   * サブアプリID（事業）有効性チェック
   */
  private validateAppId(appId: string): boolean {
    let ret = false
    this._applications?.forEach(app => {
      if (app.applicationId === appId) {
        // サプライヤー単位選択
        ret = true
      } else {
        // 事業選択
        if (!isNullOrEmpty(app.children) && Array.isArray(app.children)) {
          const target = app.children.find((x: any) => x.applicationId === appId)
          if (target) {
            ret = true
          }
        }
      }
    })
    return ret
  }

  /**
   * アプリ選択し直した場合
   * @param appId
   */
  private resetSelectedObj(appId: string): void {
    if (appId) {
      const selectedApp = this.findSelectedApp(appId)
      if (selectedApp) {
        selectedApp.selected = true
        this._currentSupplierObj = selectedApp
        if (selectedApp.applicationId === appId && selectedApp.code && selectedApp.code.length==4) {
          // サプライヤー単位選択
          this._currentAppId = appId
          this._currentAppObj = selectedApp
        } else {
          // 事業選択
          const isSubApp = selectedApp.children.find((x: any) => x.applicationId === appId)
          if (isSubApp) {
            isSubApp.selected = true
            this._currentAppId = appId
            this._currentAppObj = isSubApp
          }
        }
      }
    }
  }


  /**
   * アカウント情報
   */
  set accountInfo(v : any) {
    this._accountInfo = v
    this.accountSubject.next(v)
  }



  set monthSpan(val: number) {
    this._currentSupplierObj.monthSpan = val
  }

  set fiscalYearSetting(obj: any) {
    this._currentSupplierObj.fiscalYearSetting = obj
  }

  /**
   * アプリケーション情報
   */
  // set currentAppObj(v: any | null) {
  //   this._currentAppObj = v
  // }

  /**
   * 現在のサブアプリId
   */
  set currentAppId(appId: string) {
    if (!isNullOrEmpty(appId)) {
      localStorage.setItem(AppConsts.lastSelectedAppId, appId)
      this.clearSelected(this._applications)
      this.resetSelectedObj(appId)
      this.currentAppSubject.next(appId)
    }
  }

  _supplierCode: string =''
  set supplierCode(code: string) {
    if(!isNullOrEmpty(code)&&code.length==4) {
      this._supplierCode = code
      localStorage.setItem(AppConsts.lastSelectedSupplierCd, code)
    }
  }

  get supplierCode(): string {
    return  this._supplierCode
  }

  /**
   * アカウント情報
   * TODO: 🚨 複数の場合の選択方法
   */
  get accountInfo(): any | null {
    return this._accountInfo
  }

  get applications(): any[] {
    return this._applications || []
  }

  /**
   * ミコメルのアプリケーションID
   */
  get applicationId(): string {
    return this._currentSupplierObj?.applicationId
  }

  /**
   * ミコメルのアプリケーションID
   */
  get currentSupplierObj(): any {
    return this._currentSupplierObj
  }

  get currentSupplierCode(): string {
    return this._currentSupplierObj.code
  }

  get monthSpan(): number {
    return this._currentSupplierObj.monthSpan
  }

  /**
   * 年度締め設定値
   */
  get fiscalYearSetting(): any {
    return this._currentSupplierObj?.fiscalYearSetting
  }

  /**
   * ユーザー名
   * {FamilyName} + {FirstName}
   */
  get userName(): string {
    return `${this._accountInfo.FamilyName} ${this._accountInfo.FirstName}`
  }

  get currentAppTitle(): string {
    const currentAppId = this.currentAppId
    let ret = '未選択'
    if (currentAppId) {
      if (this.currentSupplierObj) {
        const currentSubApp = this.currentSupplierObj.children.find((x: any) => x.applicationId === currentAppId)
        if (currentSubApp) {
          ret = `${this.currentSupplierObj.name} ＞ ${currentSubApp.name}`
        } else {
          ret = `${this.currentSupplierObj.name}`
        }
      }
    }
    return ret
  }

  /**
   * 現在のサブアプリId
   */
  get currentAppId(): string | null {
    // _currentAppIdあるならそのまま返す
    if (!isNullOrEmpty(this._currentAppId)) {
      return this._currentAppId
    }
    return null
  }

  get getCurrentAppIds(): string[] {
    if (this.currentAppId) {
      if (this.currentSupplierObj.applicationId === this.currentAppId) {
        // サプライヤー単位選択した場合、事業AppIdを全て返す
        const ret = this.currentSupplierObj.children.map((x: any) => x.applicationId)
        return ret
      } else {
        // 事業だけ選択した場合、事業AppIdを返す
        return [this.currentAppId]
      }
    }
    return []
  }

  /**
   * サプライヤー単位選択中
   */
  get currentSelectedIsSupplier(): boolean {
    if (this.currentAppId) {
      if (this.currentSupplierObj.applicationId === this.currentAppId) {
        // サプライヤー単位選択
        return true
      }
    }
    return false
  }

  /**
   * ミコメルのプロダクトNo
   */
  get productNo(): string {
    return this._currentAppObj?.productInquiryCd
  }


  /** 金額単位 */
  get amountUnit(): number {
    return this._amountUnit
  }

  /** ログアウト */
  logout(): void {
    this.accountSubject.next(null)
  }




  private initAmountUnit(): number {
    const item = localStorage.getItem(AppConsts.lastSelectedAmountUnit)
    const val = Number(item)
    const unitItems = AppConsts.amountUnitItems
    if (!isNaN(val) && unitItems.find(x => x.value === val)) {
      return val
    } else {
      return AppConsts.defaultAmountUnit
    }
  }





  private findSelectedApp(appId: string | null): any {
    let selectedApp = null
    if (appId === null) return selectedApp

    this._applications?.forEach(app => {
      if (!isNullOrEmpty(app.children) && Array.isArray(app.children)) {
        if (app.applicationId === appId && (this.supplierCode === '' || app.code === this.supplierCode)) {
          selectedApp = app
        } else {
          const ret = app.children.find((x: any) => x.applicationId === appId)
          if (ret) {
            selectedApp = app
          }
        }
      }
    })
    return selectedApp
  }

  private clearSelected(apps: any): void {
    if (!isNullOrEmpty(apps) && Array.isArray(apps)) {
      apps.forEach(x => {
        x.selected = false
        if (x.children) {
          this.clearSelected(x.children)
        }
      })
    }
  }

  // 年度締め設定警告スイッチ
  _warningUnconfirmed: boolean = true
  // warning switch
  get warningUnconfirmed(): boolean {
    return this._warningUnconfirmed
  }
  set warningUnconfirmed(confirmed: boolean) {
    this._warningUnconfirmed = confirmed
  }

  _yesShowMe: boolean = false
  get yesShowMe(): boolean {
    return this._yesShowMe
  }
  set yesShowMe(val: boolean) {
    this._yesShowMe = val
  }

  _ExpectedSalesViewOriginalData: any[] | null = []
  get expectedSalesViewOriginalData(): any[] | null {
    if (this._lastSelectedAppId === this.currentAppId) {
      return this._ExpectedSalesViewOriginalData
    } else {
      return null
    }
  }
  set expectedSalesViewOriginalData(val: any[] | null) {
    if (val === null) {
      this._lastSelectedAppId = null
      this._ExpectedSalesViewOriginalData = null
      this._lockOriginalData = false
    } else {
      this._lastSelectedAppId = this.currentAppId
      this._ExpectedSalesViewOriginalData = val
      this._lockOriginalData = true
    }
  }

  // 戻る際に制御用
  // 必要なくなったけど、もしかしたら、一旦残しておく
  _lockOriginalData = true
  get lockOriginalData(): boolean {
    return this._lockOriginalData
  }
  set lockOriginalData(val: boolean) {
    this._lockOriginalData = val
  }
  _lastSelectedAppId: string | null = ''

}

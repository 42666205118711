import { Subject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class SideMenuService {

  private opened = true
  private sideMenuSwitchSubject = new Subject<boolean>()
  sideMenuSwitch$ = this.sideMenuSwitchSubject.asObservable()

  private currentMenuSubject = new Subject<string>()
  currentMenu$ = this.currentMenuSubject.asObservable()

  get switchStatus(): boolean {
    return this.opened
  }

  constructor() { }

  operateSwitch(): void {
    this.opened = !this.opened
    this.sideMenuSwitchSubject.next(this.opened)
  }

  closeIt(): void {
    this.opened = false
    this.sideMenuSwitchSubject.next(this.opened)
  }
  openIt(): void {
    this.opened = true
    this.sideMenuSwitchSubject.next(this.opened)
  }

  changedMenu(newPath: string): void {
    this.currentMenuSubject.next(newPath)
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationSubject = new Subject<any>()
  /** ユーザーがオンライン通知 */
  notification$: Observable<any>

  constructor() {
    this.notification$ = this.notificationSubject.asObservable()
  }

  showNotification(msg: string, loading: boolean): void {
    const obj = {
      msg, loading
    }
    this.notificationSubject.next(obj)
  }
}

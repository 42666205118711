import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'

import { AccountService } from '@app/services/account.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { cloneObj, isNullOrEmpty, strIns, succeeded } from '@app/helpers/utils.toolkit'
import { FiscalYearCalendarService } from '@app/services/fiscal-year-calendar.service'

import { addMonthSpan, convertUnit, createCsvData, createHeaderData, createOriginalValues, getYearMonth } from '../_helpers/view.helpers'
import { ExpectedGridComponent } from '../_components/expected-grid/expected-grid.component'
import { CommonActionAreaComponent } from '../_components/common-action-area/common-action-area.component'
import { BottomBarComponent } from '../_components/bottom-bar/bottom-bar.component'
import { CsvFileTypes, IgxCsvExporterOptions, IgxCsvExporterService } from '@infragistics/igniteui-angular'
import { NotificationService } from '@app/services/notification.service'
import { SideMenuService } from '@app/services/side-menu.service'
import { AppConsts } from '@app/configs/app.configs'
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [CommonModule, CommonActionAreaComponent, ExpectedGridComponent, BottomBarComponent],
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.scss',
  host: { class:'content-container-wrap'}
})
export class SalesComponent implements OnInit, OnDestroy {
  loading = false
  headerData: any[] = []
  gridData: any[] = []
  currentUnit: number
  targetYear: number
  targetMonth: number
  updatedDate: Date | null = null
  sideMenuOff = false
  hintUrl = AppConsts.expectedSalesHint

  private currentAppId: string | null = null
  private viewKey = 'sales'
  private originalData: any[] = []
  private cacheOriginalData: any[] = []

  /** 売上計画表示期間 設定値からもらう */
  private monthSpan: number

  private sideMenuSwitch$: Subscription
  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountSvc: AccountService,
    private sideMenuSvc: SideMenuService,
    private micomelApiSvc: MicomelApiService,
    private csvExportSvc: IgxCsvExporterService,
    private notificationSvc: NotificationService,
    private fiscalYearCalendar: FiscalYearCalendarService,
  ) {
    this.currentAppId = this.accountSvc.currentAppId
    this.currentUnit = this.accountSvc.amountUnit
    this.targetYear = this.fiscalYearCalendar.getSelectedYear(this.viewKey)
    this.targetMonth = this.fiscalYearCalendar.getSelectedMonth(this.viewKey)
    this.monthSpan = this.accountSvc.monthSpan

    this.sideMenuSwitch$ = this.sideMenuSvc.sideMenuSwitch$.subscribe(opened => {
      this.sideMenuOff = !opened
    })
  }

  ngOnInit(): void {
    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe(appId => {
      this.clearGrid()
      this.currentAppId = appId
    })

    if (this.accountSvc.expectedSalesViewOriginalData && this.accountSvc.expectedSalesViewOriginalData.length) {
      this.redisplay()
      console.log(`redisplay() ->> OK`)
    }
  }

  private clearGrid(): void {
    this.gridData = []
    this.headerData = []
  }

  changeAmountUnit(obj: any): void {
    this.currentUnit = obj.unit
    const data = cloneObj(this.originalData)
    this.gridData = convertUnit(createOriginalValues(data), this.currentUnit)
  }

  refreshGrid(obj: any): void {
    const { year, month } = obj
    this.currentUnit = obj.unit
    this.targetYear = year
    this.targetMonth = month
    const fromYearMonth = getYearMonth(year, month)
    const toYearMonth = addMonthSpan(year, month, this.monthSpan)
    this.fiscalYearCalendar.setYearMonthVal(this.viewKey, year, month)
    this.gridData = []
    this.headerData = []
    if (!this.validateParameters(this.currentAppId, fromYearMonth, toYearMonth)) {
      // Show Error Msg
      return
    }

    this.loading = true
    const appIds = this.accountSvc.getCurrentAppIds
    this.micomelApiSvc.getSelseExpected(fromYearMonth, toYearMonth, appIds)
      .subscribe({
        next: (response) => {
          if (succeeded(response)) {
            if (response.data.length) {
              this.cacheOriginalData = cloneObj(response.data) // データをキャッシュしておく
              this.originalData = cloneObj(response.data)
              this.headerData = createHeaderData(year, month, this.monthSpan)
              this.gridData = createOriginalValues(response.data)
              this.updatedDate = this.getMaxUpdatedDateTime(response.data)
              convertUnit(this.gridData, this.currentUnit)
            }
          } else {
            // 異常
            console.log(`売上計画データ取得が失敗しました。`)
          }
        },
        error: (err) => {
          this.notificationSvc.showNotification('売上計画データ取得が失敗しました。', false)
          console.log(`売上計画データ取得が失敗しました。`, err)
        },
        complete: () => {
          // いらないかも
        }
      }).add(() => {
        this.loading = false
      })

    // アクションログ送信
    this.micomelApiSvc.sendActionLog(AppConsts.actions.getSelseExpected)
  }

  redisplay(): void {
    if (this.accountSvc.lockOriginalData) {
      this.cacheOriginalData = cloneObj(this.accountSvc.expectedSalesViewOriginalData) // キャッシュデータを戻しておく

      const data = cloneObj(this.accountSvc.expectedSalesViewOriginalData)
      this.originalData = data
      console.log(`redisplay() : ->>`)
      console.log(data)

      this.headerData = createHeaderData(this.targetYear, this.targetMonth, this.monthSpan)
      this.gridData = createOriginalValues(this.originalData)
      this.updatedDate = this.getMaxUpdatedDateTime(this.originalData)
      convertUnit(this.gridData, this.currentUnit)
    }

    // クリア
    this.accountSvc.expectedSalesViewOriginalData = null
  }

  exportCsv(): void {
    if (!this.gridData || !this.gridData.length) {
      this.notificationSvc.showNotification('表示してからダウンロードしてください', false)
      return
    }
    const csvData = createCsvData(this.gridData)
    // this.csvExportService.exportData(this.localData, new IgxCsvExporterOptions('ExportedDataFile'), CsvFileTypes.CSV)
    const fileName = `売上見込み_${this.targetYear}_${this.targetMonth}`
    const opt: IgxCsvExporterOptions = new IgxCsvExporterOptions(fileName, CsvFileTypes.CSV);
    this.csvExportSvc.exportData(csvData, opt)

    // アクションログ送信
    this.micomelApiSvc.sendActionLog(AppConsts.actions.downloadSelseExpectedScv)
  }

  private getMaxUpdatedDateTime(data: any[]): Date | null {
    const objs: any = data?.map(x => new Date(x.updatedDatetime))
    const ret = new Date(Math.max.apply(null, objs))
    return ret || null
  }

  private validateParameters(appId: any, from: any, to: any): boolean {
    return !isNullOrEmpty(appId) && !isNullOrEmpty(from) && !isNullOrEmpty(to)
  }

  showDetailView(obj: any): void {
    this.accountSvc.expectedSalesViewOriginalData = cloneObj(this.cacheOriginalData)
    console.log(`showDetailView() : ->>`, obj)
    console.log(this.accountSvc.expectedSalesViewOriginalData)
    this.router.navigateByUrl(`/expected/monthlyResult/${obj.viewType}/${obj.targetMonth}`)
  }

  ngOnDestroy(): void {
    this.sideMenuSwitch$.unsubscribe()
    this.currentSubApp$.unsubscribe()
  }

}

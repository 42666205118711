<app-isbe-auth></app-isbe-auth>
<main class="main-container" *ngIf="isLoggedIn">
  <app-check-server-update></app-check-server-update>
  <app-header></app-header>
  <div class="main-content">
    <app-side-menu></app-side-menu>
    <router-outlet></router-outlet>
  </div>
</main>

<div class="logging-in" *ngIf="!isLoggedIn">
  <p>
    <img src="assets/images/icon_loading.svg" width="33" height="33" alt="" />
  </p>
  <p class="logging-in-text">
    <span>{{ loadingMsg }}</span>
  </p>
</div>
<app-global-snackbar></app-global-snackbar>

<header>
  <div class="col-left-wrapper">
    <button class="menu-btn" igxButton="icon" (click)="clickSideMenuBtn()">
      <igx-icon class="menu-btn-icon" fontSet="material">menu</igx-icon>
    </button>
    <div class="app-logo-area">
      <button class="app-logo-btn" [routerLink]="['/']" igxButton="icon">
        <div class="log-block">
          <!-- <img src="assets/images/logo_micomel_shimamura_y.svg" /> -->
          <img src="assets/images/logo_micomel_shimamura_y.png" />

        </div>
      </button>
    </div>
    <button class="app-change-btn action-btn action-btn-base" (click)="openChooseDisplayCategoryDialog()">表示カテゴリ切替え</button>
    <span class="selected-app-title">{{currentAppTitle}}</span>

    <span class="is-dev" *ngIf="isDev">検証環境</span>
  </div>
  <div class="col-right-wrapper">
    <button class="help-btn" igxButton="icon" (mouseenter)="openHelpMenu()" [matMenuTriggerFor]="helpMenu" #helpMenuTrigger="matMenuTrigger">
      <igx-icon class="help-btn-icon" fontSet="material">help</igx-icon>
    </button>
    <mat-menu #helpMenu="matMenu">
      <div class="help-menu" (mouseleave)="outHelpMenu()" (mouseenter)="enterHelpMenu()">
        <div class="action-wrapper">
          <a class="jump-link" href="https://idea-place.jp/wordpress/wp-content/themes/idea-place/assets/pdf/micomel/micomel-manual.pdf" target="_blank" rel="noopener">
            使い方
          </a>
          <a class="jump-link" href="https://idea-place.jp/contact/" target="_blank" rel="noopener">
            お問い合わせ
          </a>
          <div rel="noopener">
            プロダクトNo:{{productNo}}
          </div>
        </div>
      </div>
    </mat-menu>

    <a tabindex="-1" class="idea-link" href="http://idea-place.jp" target="_blank" rel="noopener">
      <span>idea+</span>
    </a>
    <button class="user-btn" (mouseenter)="openUserMenu()" [matMenuTriggerFor]="userMenu" style="outline: none;" #userMenuTrigger="matMenuTrigger">
      <span class="user-name">{{userName}}</span>
      <span class="user-icon-wrapper">
        <img src="assets/images/avator_default.png" alt="Author picture" />
      </span>
    </button>
    <mat-menu #userMenu="matMenu">
      <div (mouseleave)="outUserMenu()" (mouseenter)="enterUserMenu()">
        <div class="user-info">
          <span class="user-icon-wrapper">
            <img src="assets/images/avator_default.png" alt="Author picture" />
          </span>
          <span class="user-name">{{userName}}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="action-wrapper">
          <a class="jump-link" href="https://my-idea.idea-place.jp/account-change" target="_blank" rel="noopener">
            My-idea（アカウント情報）
          </a>
          <a class="logout-btn" (click)="logout()" rel="noopener">
            ログアウト
          </a>
        </div>
      </div>
    </mat-menu>
  </div>
</header>
<div class="test">
  <app-choose-display-category></app-choose-display-category>
</div>


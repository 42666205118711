<div class="content-container" [class.side-menu-off]="sideMenuOff">
  <app-common-action-area
    [currentView]="'/expected/sales'"
    [currentViewTitle]="'売上見込み'"
    [targetYear]="targetYear"
    [targetMonth]="targetMonth"
    [amountUnit]="currentUnit"
    [updatedDate]="updatedDate"
    [hintUrl]="hintUrl"
    (submit)="refreshGrid($event)"
    (changeUnit)="changeAmountUnit($event)"
  ></app-common-action-area>

  <div class="grid-container" *ngIf="gridData && gridData.length; else elseBlock">
    <app-expected-grid
      [headerData]="headerData"
      [bodyData]="gridData"
      [amountUnit]="currentUnit"
      [isExpectedSalesView]="true"
      (showDetailView)="showDetailView($event)"
    ></app-expected-grid>
  </div>
  <ng-template #elseBlock>
    <div class="skeleton-block">
      <div class="skeleton-msg" *ngIf="!loading; else elseBlock">表示開始年月を指定して「表示」ボタンをクリックしてください。</div>
      <ng-template #elseBlock>
        <div class="skeleton-msg">Loading...</div>
      </ng-template>
    </div>
  </ng-template>
</div>
<div class="bottom-bar-area">
  <app-bottom-bar (submit)="exportCsv()"></app-bottom-bar>
</div>

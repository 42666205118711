import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'

import { AccountService } from '@app/services/account.service'
import { MicomelApiService } from '@app/services/micomel.api.service'
import { cloneObj, isNullOrEmpty, succeeded } from '@app/helpers/utils.toolkit'
import { FiscalYearCalendarService } from '@app/services/fiscal-year-calendar.service'

import {
  addMonthSpan,
  convertUnit,
  createCsvData,
  createHeaderData,
  createOriginalValues,
  getYearMonth,
} from '../_helpers/view.helpers'
import { ExpectedGridComponent } from '../_components/expected-grid/expected-grid.component'
import { CommonActionAreaComponent } from '../_components/common-action-area/common-action-area.component'
import {
  CsvFileTypes,
  IgxCsvExporterOptions,
  IgxCsvExporterService,
} from '@infragistics/igniteui-angular'
import { BottomBarComponent } from '../_components/bottom-bar/bottom-bar.component'
import { NotificationService } from '@app/services/notification.service'
import { SideMenuService } from '@app/services/side-menu.service'
import { AppConsts } from '@app/configs/app.configs'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-collection',
  standalone: true,
  imports: [CommonModule, CommonActionAreaComponent, ExpectedGridComponent, BottomBarComponent],
  templateUrl: './collection.component.html',
  styleUrl: './collection.component.scss',
  host: { class: 'content-container-wrap' },
})
export class CollectionComponent implements OnInit, OnDestroy {
  loading = false
  gridData: any[] = []
  headerData: any[] = []
  currentUnit: number
  targetYear: number
  targetMonth: number
  updatedDate: Date | null = null
  sideMenuOff = false
  hintUrl = AppConsts.expectedcollectionHint
  private currentSubAppId: string | null = null
  private viewKey = 'collection'
  private originalData: any[] = []
  private monthSpan: number

  private sideMenuSwitch$: Subscription
  private currentSubApp$: Subscription = new Subscription()

  constructor(
    private accountSvc: AccountService,
    private sideMenuSvc: SideMenuService,
    private micomelApiSvc: MicomelApiService,
    private csvExportSvc: IgxCsvExporterService,
    private notificationSvc: NotificationService,
    private fiscalYearCalendar: FiscalYearCalendarService
  ) {
    this.currentSubAppId = this.accountSvc.currentAppId
    this.currentUnit = this.accountSvc.amountUnit
    this.targetYear = this.fiscalYearCalendar.getSelectedYear(this.viewKey)
    this.targetMonth = this.fiscalYearCalendar.getSelectedMonth(this.viewKey)
    this.monthSpan = this.accountSvc.monthSpan

    this.sideMenuSwitch$ = this.sideMenuSvc.sideMenuSwitch$.subscribe((opened) => {
      this.sideMenuOff = !opened
    })
  }

  ngOnInit(): void {
    this.currentSubApp$ = this.accountSvc.currentApp$.subscribe((appId) => {
      this.clearGrid()
      this.currentSubAppId = appId
    })
  }

  private clearGrid(): void {
    this.gridData = []
    this.headerData = []
  }

  changeAmountUnit(obj: any): void {
    this.currentUnit = obj.unit
    const data = cloneObj(this.originalData)
    this.gridData = convertUnit(createOriginalValues(data), this.currentUnit)
  }

  refreshGrid(obj: any): void {
    const { year, month } = obj
    this.currentUnit = obj.unit
    this.targetYear = year
    this.targetMonth = month
    const fromYearMonth = getYearMonth(year, month)
    const toYearMonth = addMonthSpan(year, month, this.monthSpan)
    this.fiscalYearCalendar.setYearMonthVal(this.viewKey, year, month)
    this.gridData = []
    this.headerData = []
    if (!this.validateParameters(this.currentSubAppId, fromYearMonth, toYearMonth)) {
      // Show Error Msg
      return
    }

    this.loading = true
    const appIds = this.accountSvc.getCurrentAppIds
    // ※親のAppIdと子のAppIdを同じ階層の配列に突っ込む
    appIds.push(this.accountSvc.currentSupplierObj.applicationId)

    const isAllCompanies = this.accountSvc.currentSelectedIsSupplier
    const SupplierCd: string | null = isAllCompanies ? this.accountSvc.currentSupplierCode : null
    console.log('SupplierCd:', SupplierCd)

    this.micomelApiSvc
      .getCollectionExpected(fromYearMonth, toYearMonth, appIds, SupplierCd)
      .subscribe({
        next: (response: any) => {
          if (succeeded(response)) {
            if (response.data.length) {
              this.originalData = cloneObj(response.data)
              this.headerData = createHeaderData(year, month, this.monthSpan)
              this.gridData = createOriginalValues(response.data)
              this.updatedDate = this.getMaxUpdatedDateTime(response.data)
              convertUnit(this.gridData, this.currentUnit)
            }
          } else {
            // 異常
            console.log(`回収見込みデータ取得が失敗しました。`)
          }
        },
        error: (err) => {
          this.notificationSvc.showNotification('回収見込みデータ取得が失敗しました。', false)
          console.log(`回収見込みデータ取得が失敗しました。`, err)
        },
      })
      .add(() => {
        this.loading = false
      })
    // アクションログ送信
    this.micomelApiSvc.sendActionLog(AppConsts.actions.getCollectionExpected)
  }

  exportCsv(): void {
    if (!this.gridData || !this.gridData.length) {
      this.notificationSvc.showNotification('表示してからダウンロードしてください', false)
      return
    }
    const csvData = createCsvData(this.gridData)
    const fileName = `回収見込み_${this.targetYear}_${this.targetMonth}`
    const opt: IgxCsvExporterOptions = new IgxCsvExporterOptions(fileName, CsvFileTypes.CSV)
    this.csvExportSvc.exportData(csvData, opt)

    // アクションログ送信
    this.micomelApiSvc.sendActionLog(AppConsts.actions.downloadCollectionExpectedCsv)
  }

  private getMaxUpdatedDateTime(data: any[]): Date | null {
    const objs: any = data?.map((x) => new Date(x.updatedDatetime))
    const ret = new Date(Math.max.apply(null, objs))
    return ret || null
  }

  private validateParameters(appId: any, from: any, to: any): boolean {
    return !isNullOrEmpty(appId) && !isNullOrEmpty(from) && !isNullOrEmpty(to)
  }

  ngOnDestroy(): void {
    this.sideMenuSwitch$.unsubscribe()
    this.currentSubApp$.unsubscribe()
  }
}

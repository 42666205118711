<div class="content-container">
  <h6 class="view-title">表示設定</h6>
  <div class="divider-horizontal"></div>
  <div class="setting-item-wrapper">
    <div class="left-part">
      <label>表示期間</label>
      <select [(ngModel)]="monthSpan" class="closing-month-select closing-select" name="selectedYear"
        (ngModelChange)="changeMonthSpan($event)">
        <option *ngFor="let item of monthSpanList" [selected]="monthSpan === item.value"
          [ngValue]="item.value">{{item.name}}
        </option>
      </select>
    </div>
    <div class="right-part">
      <!-- <button class="action-btn action-btn-base" (click)="save()">登録</button> -->
    </div>
  </div>
  <div class="divider-horizontal"></div>
  <div class="footer-action-area">
    <button class="action-btn action-btn-base" (click)="save()">設定を保存する</button>
  </div>
</div>
